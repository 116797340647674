import service from "@/plugins/request";

// www大首页
export function apiWwwIndex(params) {
  return service({
    url: "/v1/index/index",
    method: "get",
    params: params
  });
}

// 情报列表
export function apiWwwNewsList(params) {
  return service({
    url: "/v1/index/newsList",
    method: "get",
    params: params
  });
}

// app配置信息
export function apiAppConfig(params) {
  return service({
    url: "/v1/app/info",
    method: "get",
    params: params
  });
}

// app配置信息更新
export function apiAppConfigUpdate(params) {
  return service({
    url: "/v1/protected/app/editPackage",
    method: "post",
    params: params,
    successToast: true
  });
}
// 广告保存编辑
export function apiWwwAdEdit(params) {
  let url = "/v1/protected/ad/add";
  if (params && params.id) {
    url = "/v1/protected/ad/edit";
  }
  return service({
    url,
    method: "post",
    data: params,
    successToast: true
  });
}
// 广告删除
export function apiWwwAdDel(params) {
  return service({
    url: "/v1/protected/ad/delete",
    method: "post",
    data: params,
    successToast: true
  });
}
// 广告排序
export function apiWwwAdSort(params) {
  return service({
    url: "/v1/protected/ad/sort",
    method: "post",
    data: params
  });
}
// 广告列表
export function apiWwwAdList(params) {
  return service({
    url: "/v1/protected/ad/list",
    method: "get",
    params: params
  });
}

// 发私信
export function apiAdminPersonalLetter(params) {
  return service({
    url: "/v1/protected/user/adminPersonalLetter",
    method: "post",
    data: params,
    successToast: true
  });
}
//
export function apiWwwContentPageList(params) {
  return service({
    url: "/v1/index/contentPageList",
    method: "get",
    params: params
  });
}
//
export function apiWwwIndexSlider(params) {
  return service({
    url: "/v1/index/slider",
    method: "get",
    params: params
  });
}

//
export function apiWwwGameClassList(params) {
  return service({
    url: "/v1/gameClass/pageList",
    method: "get",
    params: params
  });
}

//
export function apiWwwGameVideoList(params) {
  return service({
    url: "/v1/gameVideo/pageList",
    method: "get",
    params: params
  });
}

//
export function apiWwwGameVideoAddPlayNum(params) {
  return service({
    url: "/v1/gameVideo/addPlayNum",
    method: "get",
    params: params
  });
}

//
export function apiWwwGameInfoList(params) {
  return service({
    url: "/v1/gameInfo/pageList",
    method: "get",
    params: params
  });
}

// 首页游戏推荐
export function apiSaveGameRecommend(params) {
  let url = "/v1/protected/content/recommend/add";
  if (params && params.id) {
    url = "/v1/protected/content/recommend/edit";
  }
  return service({
    url: url,
    method: "post",
    data: params
  });
}

// 游戏推荐排序
export function apiSortGameRecommend(params) {
  return service({
    url: "/v1/protected/content/recommend/sort",
    method: "post",
    data: params
  });
}

// 删除游戏推荐
export function apiDeleteGameRecommend(params) {
  return service({
    url: "/v1/protected/content/recommend/delete",
    method: "delete",
    data: params
  });
}

// 游戏推荐列表
export function apiGetGameRecommendList(params) {
  return service({
    url: "/v1/protected/content/recommend/list",
    method: "get",
    params: params
  });
}
