<template>
  <div :class="['www-container', classBase + 'www-container']" :style="setBg">
    <wiki-header v-if="device.desktop" />
    <main :class="['www-body', classBase + 'www-body']">
      <keep-alive v-if="$route.meta.keepAlive">
        <router-view />
      </keep-alive>
      <router-view v-else />
    </main>
    <wiki-footer />
  </div>
</template>

<script>
// vuex
import { mapGetters } from "vuex";

// components

import WikiHeader from "@/layout/wiki/components/wikiHeader";
import WikiFooter from "./components/wikiFooter.vue";

export default {
  name: "wwwBase",
  mixins: [],
  props: {},
  model: {},
  components: {
    WikiFooter,
    WikiHeader
  },
  data() {
    return { isShowLogin: false };
  },
  computed: {
    ...mapGetters(["gameInfo", "isHeroGame"]),
    setBg() {
      let { Config } = this.gameInfo;
      let style = {};
      if (Config && Config.backend_img && Config.backend_img.length) {
        style.background = `url(${Config.backend_img}) no-repeat fixed 50% 0`;
        style.backgroundSize = "cover";
      }
      return style;
    }
  },

  watch: {},
  created() {},
  beforeMount() {},
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {}
};
</script>

<style lang="less" scoped>
.pc-www-container {
  width: 100%;
  height: 100%;
  //background: #fff;
}

.www-body {
  width: 1300px;
  margin: auto;
  padding-top: 69px;
  margin-bottom: 20px;
}
</style>
