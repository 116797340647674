import Vue from "vue";
import axios from "axios";
import { removeCookies, getCookies } from "../cookies";
import { Message } from "element-ui";
import jsToApp from "../jsToApp";

const service = axios.create({
  baseURL: "",
  timeout: 1000 * 60,
  headers: {
    "X-Requested-With": "XMLHttpRequest"
  }
});
const err = error => {
  // entry-client.js || entry-server.js
  const store = Vue.__VUEX_STORE__;
  const device = store.getters.device;

  if (error.message.includes("timeout")) {
    if (device.inApp) {
      jsToApp("app_toast", error.msg);
    } else {
      Message({
        message: "请求超时",
        type: "error"
      });
    }
  }
  return Promise.reject(error);
};

// 请求拦截
service.interceptors.request.use(config => {
  // console.log('request----->', config);

  // entry-client.js || entry-server.js
  const store = Vue.__VUEX_STORE__;

  // console.log("axios store 2", store.getters, config);

  const userToken = store.getters.userToken;
  // const game_id = store.getters.gameInfo.id || 0;
  const alias = store.getters.alias;
  const device = store.getters.device;
  // 设置token
  config.headers["Access-Token"] = userToken || "";
  // config.headers["game-id"] = game_id
  config.headers["game-alias"] = alias;
  // 来源 0未知 1 pc 2 wap 3 android 4 ios
  config.headers["device-num"] = device.inApp ? 3 : device.desktop ? 1 : 2;
  // 是否青少年模式
  const isTeeny = getCookies("underageMode") === "teeny";
  if (isTeeny) {
    config.headers["Is-Limit-Age"] = 1;
  }
  return config;
}, err);

// 回调拦截
service.interceptors.response.use(response => {
  // console.log('response----->', response);

  // entry-client.js || entry-server.js
  const store = Vue.__VUEX_STORE__;
  const device = store.getters.device;

  const config = response.config;
  let res = response.data;
  if (res.code === 0) {
    // 提示成功信息
    if (config && config.successToast) {
      if (device.inApp) {
        jsToApp("app_toast", res.msg);
      } else {
        Message({
          message: res.msg,
          type: "success"
        });
      }
    }
    return res;
  }
  // 401 退出登录
  else if (res.code == 401) {
    store.commit("set_userToken", "");
    store.commit("set_userUid", "");
    removeCookies("userUid");
    removeCookies("userToken");
  }
  // 100011 多人同时编辑
  // 100012 具有违禁信息
  else if (res.code === 100011 || res.code === 100012) {
    return Promise.reject(res);
  }
  // 其他错误情况
  else {
    console.log("config-----");
    console.log(JSON.stringify(res));
    if (config.errorToast || config.errorToast === undefined) {
      if (device.inApp) {
        jsToApp("app_toast", res.msg);
      } else {
        Message({
          message: res.msg,
          type: "error"
        });
      }
    }
    return Promise.reject(res);
  }
}, err);

export default service;
