import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "./locale/en.json";
import zhCN from "./locale/zh-cn.json";
import zhHK from "./locale/zh-hk.json";

Vue.use(VueI18n);
const langs = {
  en: en,
  "zh-cn": zhCN,
  "zh-hk": zhHK
};

// 获取系统语言
function getLang(language) {
  const sysLang =
    !language && typeof window !== "undefined"
      ? window.navigator.language.toLocaleLowerCase()
      : "zh-cn";
  let lang = language ? language.toLocaleLowerCase() : "zh-cn";
  // 英文类的语言
  if (sysLang.startsWith("en")) {
    lang = "en";
  }
  // 中文类的语言
  if (sysLang.startsWith("zh-")) {
    if (sysLang != "zh-cn") {
      lang = "zh-hk";
    }
  }
  return lang;
}

export { getLang };
// export default new VueI18n({
//   locale: getLang(),
//   messages: langs
// });

/**
 * 创建I18n
 * @returns VueI18n
 */
export function createI18n(lang) {
  return new VueI18n({
    locale: getLang(lang),
    messages: langs
  });
}
