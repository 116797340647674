<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible="visible"
    width="415px"
    append-to-body
    center
    custom-class="login-dialog"
    @close="onClose"
  >
    <div slot="title" class="title">{{ loginUser.verify_status ? "实名认证" : "手机号验证" }}</div>
    <div class="tips" v-if="loginUser.verify_status">根据国家法律规定，必须实名认证后才能继续</div>
    <div class="tips" v-else>
      根据国家法律规定，必须手机号验证后才能继续<br />
      注：该手机号仅用于验证，并不作为登陆账号
    </div>
    <el-form ref="loginForm" :model="form" :rules="rules" class="login-form">
      <el-form-item prop="id_name" v-if="loginUser.verify_status">
        <el-input v-model="form.id_name" maxlength="36" placeholder="请输入姓名" size="default" />
      </el-form-item>
      <el-form-item prop="id_card" v-if="loginUser.verify_status">
        <el-input v-model="form.id_card" maxlength="18" placeholder="请输入身份证号" size="default" />
      </el-form-item>
      <el-form-item prop="phone">
        <el-input
          v-model="form.phone"
          maxlength="11"
          placeholder="请输入手机号"
          size="default"
          :disabled="loginUser.verify_level == 1"
        />
      </el-form-item>
      <el-form-item prop="code">
        <el-input v-model="form.code" maxlength="6" placeholder="请输入验证码" size="default">
          <el-button slot="append" :disabled="timeCount > 0" class="code-btn" type="default" @click="showImgCode">
            {{ codeText }}
          </el-button>
        </el-input>
      </el-form-item>
      <el-button class="W100" size="default" type="primary" @click="onAuth">
        确 定
      </el-button>
    </el-form>
    <div style="padding-top: 10px;">
      <label class="xy">
        <el-checkbox v-model="isChecked" />
        勾选即表示同意GameKee
        <a class="" :href="`//www.${wikiDomain}/wiki/91660.html`" target="_blank">
          《隐私政策》
        </a>
        和
        <a :href="`//www.${wikiDomain}/wiki/155685.html`" target="_blank">
          《用户协议》
        </a>
        内容条例
      </label>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="imgCodeVisible"
      :width="device.desktop ? '300px' : '75%'"
      append-to-body
      center
      custom-class="login-dialog"
      top="20vh"
    >
      <div slot="title" class="title">请输入图片验证码</div>
      <el-form ref="imgForm" :model="form" :rules="rules" class="login-form">
        <el-form-item>
          <el-row justify="space-between" type="flex">
            <el-col :span="14" class="img-box">
              <img :src="imgSrc" alt="" class="captcha-img" />
            </el-col>
            <el-col :span="8">
              <span class="reset-img-btn" @click="getCaptcha">换一张</span>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item prop="img_code">
          <el-input v-model="form.img_code" maxlength="6" placeholder="请输入图形验证码" size="default" />
        </el-form-item>
      </el-form>

      <el-button class="W100" size="default" type="primary" @click="sendSmsCode">
        确定
      </el-button>
    </el-dialog>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { apiPublicGetCaptcha, apiPublicSendSmsCode } from "@/api/publicApi";
import { apiUserVerify } from "@/api/userApi";
// plugins
import eventBus from "@/plugins/eventBus";

export default {
  name: "login",
  data() {
    return {
      visible: false,
      imgCodeVisible: false,
      loading: false,
      isChecked: false,
      form: {
        id_name: "",
        id_card: "",
        phone: "",
        code: "",
        img_code: ""
      },
      imgSrc: "",
      rules: {
        id_name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        id_card: [
          { required: true, message: "请输入身份证号", trigger: "blur" },
          {
            pattern: /^[1-9]\d{5}(19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i,
            message: "身份证号不正确",
            trigger: "blur"
          }
        ],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { pattern: /^[1][0-9]{10}$/, message: "手机号不正确", trigger: "blur" }
        ],
        img_code: [{ required: true, message: "请输入手机验证码", trigger: "blur" }],
        code: [{ required: true, message: "请输入短信验证码", trigger: "blur" }]
      },
      codeText: "获取验证码",
      timeCount: 0
    };
  },
  computed: {
    ...mapGetters(["loginUser"])
  },
  beforeMount() {
    eventBus.$on("onShowAuthRealName", this.onOpen);
  },
  mounted() {
    if (this.loginUser.verify_level == 1) {
      this.form.phone = this.loginUser.phone;
    }
  },
  methods: {
    // 展示登录弹窗
    onOpen() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },

    showImgCode() {
      if (this.timeCount > 0) return;
      this.$refs.loginForm.validateField(["phone"], err => {
        if (!err) {
          this.getCaptcha();
          this.imgCodeVisible = true;
        }
      });
      // this.imgCodeVisible = true;
    },
    // 获取短信验证码
    sendSmsCode() {
      let { form } = this;
      let isErr = false;
      this.$refs.loginForm.validateField(["phone", "img_code"], async err => {
        console.log(err);
        if (err) {
          isErr = true;
        }
      });
      if (isErr) return;
      this.imgCodeVisible = false;
      let params = {
        phone: form.phone,
        img_code: form.img_code,
        scene: 4 // 实名认证场景
      };
      this.codeText = "60s" + "后重新获取";
      this.timeCount = 60;
      apiPublicSendSmsCode(params).then(res => {
        if (res.code == 0) {
          let timer = setInterval(() => {
            if (this.timeCount == 0) {
              this.codeText = "重新获取";
              clearInterval(timer);
              return;
            }
            this.timeCount--;
            this.codeText = this.timeCount + "后重新获取";
          }, 1000);
        }
      });
    },
    // 获取图形验证码
    getCaptcha() {
      apiPublicGetCaptcha({ is_hero: this.isHeroGame ? 1 : 0 }).then(res => {
        if (res.code == 0) {
          this.imgSrc = res.data.image_url;
        }
      });
    },
    onAuth() {
      this.$refs.loginForm.validate(async valid => {
        if (!valid) return;
        if (!this.isChecked) {
          this.$message("请先同意隐私政策");
          return false;
        }
        const { code } = await apiUserVerify(this.form);
        if (code == 0) {
          this.$message.success("实名认证完成");
          this.visible = false;
          window.location.reload();
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.title {
  font-size: 18px;
  text-align: center;
}

.tips {
  font-size: 14px;
  line-height: 24px;
  color: #333;
  margin-bottom: 10px;
}

.img-box {
  display: flex;
  height: auto;
  height: 40px;
}

.reset-img-btn {
  display: block;
  margin: auto;
  width: 50px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  color: #3fa0f4;
  cursor: pointer;
  user-select: none;
}

.captcha-img {
  background: #f5f5f5;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  line-height: 1;
  overflow-x: hidden;
  object-fit: fill;
}

.code-btn {
  font-weight: 400;
}

.xy {
  padding-top: 10px;
  font-size: 12px;
  color: @gray;
  cursor: pointer;
  line-height: 32px;
  a {
    color: @text-basic;
  }
}
</style>
