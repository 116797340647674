import service from "@/plugins/request";

// 游戏详情
export function apiGameInfo(params) {
  return service({
    url: "/v1/game/detail",
    method: "get",
    params: params
  });
}

// wiki详情编辑
export function apiWikiEdit(params) {
  return service({
    url: "/v1/protected/game/edit",
    method: "post",
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 友情链接编辑
export function apiWikiEditFriendLink(params) {
  return service({
    url: "/v1/protected/game/config",
    method: "post",
    data: params,
    successToast: true,
    errorToast: true
  });
}

// wikiBanner
export function apiGetRecommend(params) {
  return service({
    url: "/v1/protected/slider/list",
    method: "get",
    data: params
  });
}

// 编辑wikiBanner
export function apiEditRecommend(params) {
  return service({
    url: "/v1/protected/slider/edit",
    method: "post",
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 创建游戏
export function apiGameAdd(params) {
  return service({
    url: "/v1/protected/game/add",
    method: "post",
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 删除游戏
export function apiGameDel(params) {
  return service({
    url: "/v1/protected/game/del",
    method: "post",
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 游戏列表
export function apiGamePageList(params) {
  return service({
    url: "/v1/game/pageList",
    method: "get",
    params: params
  });
}

// 游戏版本列表
export function apiGameTimeLineList(params) {
  return service({
    url: "/v1/protected/gameTimeLine/list",
    method: "get",
    params: params
  });
}

// 编辑游戏版本
export function apiGameTimeLineEdit(params) {
  let url = "/v1/protected/gameTimeLine/add";
  if (params.id) {
    url = "/v1/protected/gameTimeLine/edit";
  }
  return service({
    url,
    method: "post",
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 删除游戏版本
export function apiGameTimeLineDel(params) {
  return service({
    url: "/v1/protected/gameTimeLine/del",
    method: "post",
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 开通wiki
export function apiGameWikiCreate(params) {
  return service({
    url: "/v1/protected/wiki/create",
    method: "post",
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 开通wiki
export function apiGameStoreArea(params) {
  return service({
    url: "/v1/game/storeArea",
    method: "get"
  });
}

// wiki游戏详情
export function apiWikiGameInfo(params) {
  return service({
    url: "/v1/gameInfo/detail",
    method: "get",
    params: params
  });
}
// 游戏列表
export function apiGameList(params) {
  return service({
    url: "/v1/game/list",
    method: "get",
    params: params
  });
}

// 游戏视频观看
export function apiGameVideoAddPlayNum(params) {
  return service({
    url: "/v1/gameVideo/addPlayNum",
    method: "get",
    params: params
  });
}

// 云游戏绑定列表
export function apiCloudGameBindList(params) {
  return service({
    url: "/v1/protected/game/cloud-game/page-list",
    method: "get",
    params: params
  });
}

// 云游戏绑定
export function apiCloudGameBind(params) {
  return service({
    url: "/v1/protected/game/cloud-game/bind",
    method: "post",
    data: params
  });
}

// 云游戏解绑
export function apiCloudGameUnbind(params) {
  return service({
    url: "/v1/protected/game/cloud-game/unbind",
    method: "post",
    data: params
  });
}

// 游戏别名重定向列表
export function apiGameAliasRedirectList(params) {
  return service({
    url: "/v1/protected/gameAlias/pageList",
    method: "get",
    params: params
  });
}

// 游戏别名重定向添加
export function apiGameAliasRedirectAdd(params) {
  return service({
    url: "/v1/protected/gameAlias/add",
    method: "post",
    data: params
  });
}

// 游戏别名重定向删除
export function apiGameAliasRedirectDelete(params) {
  return service({
    url: "/v1/protected/gameAlias/delete",
    method: "post",
    data: params
  });
}
