import Vue from "vue";
import VueRouter from "vue-router";

// routes
import wwwRouter from "./wwwRouter";
import wikiRouter from "./wikiRouter";
import userRouter from "./userRouter";
import msgRouter from "./msgRouter";
import otherRouter from "./otherRouter";
import adminWwwRouter from "./adminWwwRouter";
import adminRouter from "./adminRouter";
import externalRouter from "./externalRouter";

Vue.use(VueRouter);

/**
 * 创建router函数
 * @returns router
 */
export function createRouter(isWww) {
  return new VueRouter({
    mode: "history",
    routes: [
      ...wwwRouter,
      ...adminWwwRouter,
      ...userRouter,
      ...msgRouter,
      ...otherRouter,
      // 404路由，必须放置在routes末尾
      {
        path: "/404",
        name: "404页面",
        component: () => import("@/views/404"),
        hidden: true,
        meta: {
          title: "Gamekee"
        }
      },
      ...wikiRouter,
      ...adminRouter,
      ...externalRouter,
      { path: "*", redirect: "/404", hidden: true }
    ],
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition;
      }
      return {
        x: 0,
        y: 0
      };
    }
  });
}
