<template>
  <el-dialog
    :visible.sync="visible"
    append-to-body
    center
    :title="isShowApply ? '申请wiki' : '申请须知'"
    :width="!isShowApply ? '420px' : '600px'"
  >
    <div class="apply-info" v-if="!isShowApply">
      <el-scrollbar ref="scrollbar" class="apply-scrollbar">
        <div class="info-title">选择GameKee的理由</div>
        <div class="info-item">
          ① 搭建wiki所需的服务器和带宽成本均由本站承担，版主无需担心。
        </div>
        <div class="info-item">
          ②
          我们降低了搭建wiki的门槛，编辑wiki不需要你有任何写代码的能力，只要你有时间，有搭建wiki的热情，以及一台电脑就可以搭建wiki，可以先去看看我们的wiki操作指南。
        </div>
        <div class="info-item">
          ③ 我们不对游戏进行限制，各种类型的游戏都可以申请攻略网站。
        </div>
        <div class="info-item">
          ④ 我们也会向wiki版主提供一定的技术或美术方面的支持。
        </div>
        <div class="info-item">
          ⑤ 用心打造wiki的版主，我们还会提供更多网站曝光的机会和线上线下的奖励。
        </div>
        <br />
        <div class="info-title">申请wiki的注意事项</div>
        <div class="info-item">
          ①
          我们提供低门槛的wiki建设平台，并不代表对版主没有任何条件，在本站活跃的老用户，我们会免除审核直接给与建站权限。
        </div>
        <div class="info-item">
          ②
          如果你是一名完全没有经验的新用户，我们建议你先尝试在本站发表内容，熟悉编辑器操作，甚至加入某个游戏wiki的攻略组实习一段时间，再考虑创建wiki。
        </div>
        <div class="info-item">
          ③
          wiki是长线运营的，版主会持续在网站上投入大量的时间和精力，很多版主在尝试一周或一个月后便放弃了，这些废弃的wiki就成了一堆电子垃圾，这不是我们所期望的，所以在申请wiki之前，请考虑好自己能否抽出生活和工作之外的精力去持续维护一个wiki网站。
        </div>
        <div class="info-item">
          ④ 如果版主长期不维护wiki，其他管理员有权申请替代版主，如果wiki长时间没有更新，我们也会回收wiki。
        </div>
        <div class="info-item">
          ⑤
          大多情况下，wiki不是一个人能搭建好的，都是有攻略组支撑的，版主意味着责任，需要起到带头作用，招募、宣传、并成立自己的攻略组，挖掘更多和你一样热爱游戏并愿意配合你的运营方向持续的为爱发电。
        </div>
      </el-scrollbar>
      <div class="apply-btns">
        <el-button @click="handleApply" type="primary" :disabled="!isScrollBottom" style="width: 200px;">
          申请wiki
        </el-button>
      </div>
    </div>
    <div class="form-content" v-else>
      <div class="form-item">
        <div class="form-title">游戏名称</div>
        <div class="form-input">
          <input v-model="form.name" type="text" placeholder="请输入您想创建的游戏" />
        </div>
      </div>
      <div class="form-item">
        <div class="form-title">
          别名申请<span class="tips">（支持数字+字母模式，不可纯数字，如www.gamekee.com/ba）</span>
        </div>
        <div class="form-input">
          <span style="margin-right: 14px;">www.gamekee.com/</span>
          <input v-model="form.alias" type="text" placeholder="请输入别名" />
        </div>
      </div>
      <div class="form-item">
        <div class="form-title">联系方式<span class="tips">（QQ/微信均可）</span></div>
        <div class="form-input">
          <input v-model="form.contact" type="text" placeholder="请输入联系方式" />
        </div>
      </div>
      <div class="form-item">
        <div class="form-title">申请原因</div>
        <textarea v-model="form.reason" class="form-textarea"></textarea>
      </div>
      <div class="tCenter mT20">
        <el-button class="apply-btn" type="primary" @click="onOk">提交</el-button>
      </div>
      <div class="btn-tips">
        注：提交申请后请主动联系K站管理员<a
          href="https://jq.qq.com/?_wv=1027&k=xmIMNUxz"
          target="_blank"
          rel="external nofollow"
          >(QQ：2017946590)</a
        >可以加快审核进度。
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { apiUserApplyWiki } from "@/api/userApi";

export default {
  name: "applyWikiDg",
  data() {
    return {
      visible: false,
      form: {
        alias: "",
        contact: "",
        name: "",
        reason: ""
      },
      isShowApply: false,
      isScrollBottom: false
    };
  },
  watch: {
    visible(val) {
      this.form = {
        alias: "",
        contact: "",
        name: "",
        reason: ""
      };
      if (val) {
        this.$nextTick(() => {
          this.handleScroll();
        });
      }
    }
  },
  mounted() {},
  methods: {
    handleApply() {
      this.isShowApply = true;
    },
    handleScroll() {
      if (this.$refs.scrollbar) {
        let scrollbarEl = this.$refs.scrollbar.wrap;
        scrollbarEl.onscroll = () => {
          if (scrollbarEl.scrollTop >= scrollbarEl.scrollHeight - 400 - 20) {
            this.isScrollBottom = true;
          }
        };
      }
    },
    onOk() {
      if (!this.form.name) {
        this.$message("请输入申请的游戏名称");
        return;
      }
      if (!this.form.alias) {
        this.$message("请输入申请的域名");
        return;
      }
      if (!this.form.contact) {
        this.$message("请输入联系方式");
        return;
      }
      if (!this.form.reason) {
        this.$message("请输入申请原因");
        return;
      }

      apiUserApplyWiki(this.form).then(res => {
        if (res.code !== 0) return;
        this.visible = false;
      });
    }
  }
};
</script>

<style scoped lang="less">
.apply-scrollbar {
  height: 400px;
  /deep/ .el-scrollbar__wrap {
    overflow-x: hidden;
  }
}
.apply-info {
  width: 100%;
  .info-title {
    font-size: 14px;
    font-weight: 600;
    line-height: 36px;
  }
  .info-item {
    font-size: 14px;
    line-height: 24px;
  }

  .apply-btns {
    margin: 32px 0;
    text-align: center;
  }
}
.form-content {
  padding: 20px 40px;
}
.form-item {
  margin-bottom: 30px;
}
.form-title {
  font-size: 14px;
  font-weight: 500;
  color: #171717;

  .tips {
    color: #999999;
    font-weight: 400;
    font-size: 12px;
  }
}

.form-input {
  height: 32px;
  margin-top: 4px;
  display: flex;
  align-items: center;

  input {
    height: 100%;
    flex: 1;
    border: none;
    border-bottom: 1px solid #ededed;
    &::placeholder {
      color: #d1d1d1;
    }
  }
}

.form-textarea {
  background: #f8f8f8;
  height: 140px;
  width: 100%;
  border-radius: 4px;
  resize: none;
  border: none;
  padding: 10px;
  margin-top: 12px;
}
.apply-btn {
  width: 100%;
  height: 36px;
}
.btn-tips {
  font-size: 12px;
  color: #999;
  margin-top: 28px;
  a {
    color: #00afe8;
    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
