<template>
  <div class="wiki-base">
    <div :style="setBg" class="wiki-base-layout">
      <wiki-header />
      <main id="wiki-body" class="wiki-body">
        <!-- <wiki-nav/>-->
        <div
          :style="{
            width: $route.name === 'wikiHome' ? '1224px' : '1380px'
          }"
          class="wiki-content"
          :class="alias + '-wiki-content'"
        >
          <keep-alive>
            <router-view v-if="$route.meta.keepAlive" />
          </keep-alive>
          <router-view v-if="!$route.meta.keepAlive" />
        </div>
      </main>
      <wiki-footer />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import dayjs from "dayjs";
// components
import WikiHeader from "@/layout/wiki/components/wikiHeader";
import WikiFooter from "./components/wikiFooter.vue";
// import AppDownBtn from "../../views/wiki/components/appDownBtn";
// apis
import { apiWikiAdminList } from "@/api/wikiApi";
// plugins
// import eventBus from "@/plugins/eventBus";
// mixins
import calcMixin from "@/mixins/calcMixin";
import mergeAsyncDataMixin from "@/mixins/mergeAsyncDataMixin";

export default {
  name: "wikiBase",
  props: {},
  model: {},
  components: {
    WikiFooter,
    WikiHeader
  },
  mixins: [calcMixin, mergeAsyncDataMixin],
  data() {
    return {
      isSSRGetData: false
    };
  },
  async asyncData({ store, $apis }) {
    try {
      console.log("asyncData start");
      const isWww = store.state.isWww;
      if (isWww) return;
      const { code, data } = await $apis.apiWikiAdminList();
      if (code == 0) {
        store.commit("set_adminList", data || []);
      }
      console.log("asyncData end");
      return {
        isSSRGetData: true
      };
    } catch (err) {
      console.log(err);
    }
  },
  computed: {
    ...mapGetters(["gameInfo", "isHeroGame"]),
    isInWiki() {
      const routeNames = [
        "wikiHome",
        "wikiNew",
        "wikiGame",
        "wikiDetail",
        "wikiDetailContributor",
        "wikiList",
        "noWikiGame",
        "noWikiDetail",
        "noWikiList"
      ];
      let { $route } = this;
      return routeNames.includes($route.name);
    },
    isInHome() {
      const routeNames = ["wikiHome", "wikiGame", "wikiNew"];
      let { $route } = this;
      return routeNames.includes($route.name);
    },
    setBg() {
      let { Config } = this.gameInfo;
      // 个人中心不展示背景
      if (this.isInWiki) {
        let style = {};
        if (Config && Config.backend_img && Config.backend_img.length) {
          style.background = `url(${Config.backend_img}) no-repeat fixed 50% 0`;
          style.backgroundSize = "cover";
        }
        return style;
      } else {
        return "";
      }
    }
  },
  watch: {},
  created() {},
  beforeMount() {
    // 判断服务端是否已获取数据
    if (!this.isSSRGetData) {
      this.getAdminList();
    }
    // 强制显示登录框
    if (this.$route.query.is_super && this.$route.query.is_super == 1) {
      this.isShowLogin = true;
    }
    // let showLogin = sessionStorage.getItem('showLogin');
    // if (!this.loginUid && showLogin != '0' && device.desktop) {
    //   this.isShowLogin = true;
    // }
  },
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  methods: {
    getAdminList() {
      if (this.isWww) return;
      apiWikiAdminList().then(({ data }) => {
        this.$store.commit("set_adminList", data || []);
      });
    }
  }
};
</script>

<style lang="less" scoped>
.wiki-base {
  width: 100%;
  height: 100%;
  .wiki-base-layout {
    width: 100%;
    height: 100%;
    overflow: auto;
    .wiki-body {
      width: 100%;
      height: 100%;
      box-sizing: border-box;
    }
  }
}
</style>
