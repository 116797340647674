import Vue from "vue";
import Vuex from "vuex";
import { getCookies, removeCookies, setCookies } from "../plugins/cookies";
import { dateFormat } from "@/plugins/util";
import { apiRedPoint, apiUserLoginInfo, apiUserLoginOut, apiUserMyPermission, apiUserSdkAuth } from "@/api/userApi";
import { flatRoleKeys } from "../roleConfig";

Vue.use(Vuex);

/**
 * 创建vuex store
 * @returns
 */
export function createStore() {
  return new Vuex.Store({
    state: {
      ssrComponentData: [],
      isWww: true,
      isHasWiki: false,
      alias: "www",

      redPoint: {
        zan: 0,
        replay: 0,
        system: 0,
        total: 0
      },
      gameInfo: {
        name: "",
        alias: "",
        icon: ""
      },
      userInfo: {},
      userToken: "",
      userUid: "",
      isHeroGame: false,
      needLogin: true,
      roleInfo: [],
      isLoadRoleInfo: false,
      device: {},
      // 管理员列表
      adminList: [],
      pageSource: "gamekee"
    },
    getters: {
      ssrComponentData: state => state.ssrComponentData,
      isWww: state => state.isWww,
      isHasWiki: state => state.isHasWiki,
      alias: state => state.alias,
      isHorizontal: state => state.device.orientation == "landscape",
      pageSource: state => state.pageSource,
      redPoint: state => state.redPoint,
      userToken: state => state.userToken,
      userUid: state => state.userUid,
      loginUser: state => state.userInfo,
      loginUid: state => {
        // let userUid = state.userInfo.uid || localStorage.getItem('userUid') || 0;
        let userUid = state.userInfo.uid || 0;
        return userUid;
      },
      isSuper: state => {
        return state.userInfo.is_super || 0;
      },
      roleInfo: state => state.roleInfo,
      isLoadRoleInfo: state => state.isLoadRoleInfo,
      gameInfo: state => state.gameInfo,
      adminList: state => state.adminList,
      device: state => state.device,
      wikiDetailInfo: state => {
        let {
          icon,
          name,
          alias,
          is_wiki_open,
          description,
          banner,
          theme_name,
          title_bg,
          seo_title,
          seo_description,
          seo_keywords,
          slogan,
          statics_account_id,
          content_view_num
        } = state.gameInfo;

        return {
          icon,
          name,
          alias,
          slogan,
          is_wiki_open: Number(is_wiki_open) || 0,
          description,
          banner,
          theme_name,
          title_bg,
          seo_title,
          seo_description,
          seo_keywords,
          statics_account_id,
          content_view_num
        };
      },
      isHeroGame: state => {
        // return true;
        return state.isHeroGame;
      },
      needLogin: state => {
        // return false;
        return state.needLogin;
      },
      packageName: state => {
        return state.gameInfo.packageName;
      },
      version: state => {
        let version = state.gameInfo.version || [];
        version.forEach(item => {
          item.disable = true;
          item.up_at = dateFormat(item.up_at).ymd;
          return item;
        });
        return version;
      },
      friendLink: state => {
        let friend_link = [];
        if (state.gameInfo.Config && state.gameInfo.Config.friend_link) {
          friend_link = JSON.parse(state.gameInfo.Config.friend_link);
        }
        return friend_link;
      }
    },
    mutations: {
      set_ssr_component_data(state, ssrComponentData) {
        state.ssrComponentData = ssrComponentData;
      },
      set_redPoint(state, redPoint) {
        state.redPoint = redPoint;
      },
      set_redPointType(state, data) {
        state.redPoint[data.type] = data.value;
      },
      set_pageSource(state, info) {
        state.pageSource = info;
      },
      set_userInfo(state, info) {
        if (typeof window !== "undefined" && window.staticFn) {
          window.staticFn.setConfig({ uid: info.uid });
        }

        state.userInfo = info;
      },
      set_userToken(state, userToken) {
        state.userToken = userToken;
      },
      set_userUid(state, userUid) {
        state.userUid = userUid;
      },
      set_roleInfo(state, info) {
        state.roleInfo = info;
        // console.log(state.roleInfo);
        state.isLoadRoleInfo = true;
      },
      set_userLessInfo(state, info) {
        state.userInfo = Object.assign(state.userInfo, info);
      },
      set_gameInfo(state, info) {
        if (!info.friend_link) {
          info.friend_link = [];
        }
        state.isHeroGame = info.is_hero == 1;
        state.needLogin = info.is_need_login == 1;
        state.gameInfo = info;
      },
      set_screenInfo(state, info) {
        state.device = Object.assign(state.device, info);
      },
      set_adminList(state, list) {
        state.adminList = list;
      },
      set_device(state, device) {
        state.device = device;
      },
      set_isWww(state, isWww) {
        state.isWww = isWww;
      },
      set_alias(state, alias) {
        state.alias = alias;
      },
      set_isHasWiki(state, isHasWiki) {
        state.isHasWiki = isHasWiki;
      }
    },
    actions: {
      // 拉取用户信息
      async getUserInfo({ commit }) {
        if (window.location.host == "help.gamekee.com") return;
        const userToken = getCookies("userToken");
        let sdkParams = JSON.parse(sessionStorage.getItem("sdkParams") || "{}");
        if (sdkParams.sign) {
          const res = await apiUserSdkAuth(sdkParams);
          if (res.code === 0) {
            commit("set_userInfo", res.data.user);
            commit("set_userToken", res.data.token);
            commit("set_userUid", res.data.user.uid);

            setCookies("userToken", res.data.token);
            setCookies("userUid", res.data.user.uid);
            this.dispatch("getRoleInfo", userToken);
            this.dispatch("getRedPoint");
          }
          return false;
        }
        if (!userToken || userToken == "") {
          return false;
        }
        await apiUserLoginInfo()
          .then(res => {
            if (res.code == 0) {
              commit("set_userInfo", res.data.user);
              commit("set_userToken", res.data.token);
              commit("set_userUid", res.data.user.uid);

              setCookies("userToken", res.data.token);
              setCookies("userUid", res.data.user.uid);
              this.dispatch("getRoleInfo", userToken);
              this.dispatch("getRedPoint");
            }
          })
          .catch(() => {
            // token过期，登出
            this.dispatch("loginOut");
          });
      },

      getRedPoint({ commit }) {
        if (this.loginUid <= 0) return;

        apiRedPoint()
          .then(res => {
            let redPoint = {
              zan: 0,
              replay: 0,
              system: 0,
              total: 0
            };
            if (res.code == 0) {
              res.data.forEach(item => {
                if ([1, 2].includes(item.type)) {
                  redPoint.zan += item.counts;
                } else if ([3, 4].includes(item.type)) {
                  redPoint.replay += item.counts;
                } else if ([5, 6, 7, 8].includes(item.type)) {
                  redPoint.system += item.counts;
                }
                redPoint.total = redPoint.zan + redPoint.replay + redPoint.system;
                commit("set_redPoint", redPoint);
              });
            }
          })
          .catch(error => {
            console.log(error);
          });
      },
      // 获取用户权限信息
      getRoleInfo({ commit, state }, userToken) {
        const userInfo = state.userInfo;
        if (userInfo.is_super) {
          // console.log(flatRoleKeys);
          commit("set_roleInfo", flatRoleKeys);
          return;
        }
        if (!userToken || userToken == "") {
          commit("set_roleInfo", []);
          return;
        }
        apiUserMyPermission()
          .then(res => {
            if (res.code == 0) {
              commit("set_roleInfo", res.data ? res.data.unique_keys : []);
            }
          })
          .catch(error => {
            console.log(error);
          });
      },
      // 登出
      async loginOut({ commit, state }, type) {
        if (state.device.inApp) {
          // jsToApp('app_loginOut');
          commit("set_userToken", "");
          commit("set_userUid", "");

          removeCookies("userUid");
          removeCookies("userToken");
        } else {
          try {
            await apiUserLoginOut();
          } catch (e) {
            console.log(e);
          }
          setTimeout(() => {
            commit("set_userToken", "");
            commit("set_userUid", "");

            removeCookies("userUid");
            removeCookies("userToken");
            window.location.reload();
          }, 500);

          // window.location.replace('/');
        }
      }
    },
    modules: {}
  });
}

const store = createStore();

export default store;
