<template>
  <header class="wiki-header">
    <div class="l-wrapper">
      <a v-if="isHeroGame" class="hero-logo" href="/"></a>
      <a v-else class="gamekee-logo" :href="`//www.${wikiDomain}`"></a>
      <div v-if="!isHeroGame" class="nav">
        <template>
          <a v-for="(item, index) in navGroup" :key="index" :href="`//www.${wikiDomain + item.url}`">{{ item.text }}</a>
        </template>
        <!-- <a href="javascript:void(0)">
          <el-popover
            popper-class="app-download-card-popover"
            :visible-arrow="true"
            trigger="hover"
            placement="bottom-start"
            width="430"
          >
            <div class="app-download-card">
              <div class="download-qrcode">
                <img src="@/assets/images/app-download-qrcode.png" alt="二维码" />
              </div>
              <div class="download-tips">
                <div class="title">扫码下载 GameKee APP</div>
                <div class="desc">
                  <div>同好共聊，热火朝天!</div>
                  <div>资讯wiki，快人一步!</div>
                </div>
              </div>
              <div class="card-tag"><i class="wiki-icon wiki-icon-android"></i>仅限安卓用户</div>
            </div>
            <span slot="reference">下载客户端</span>
          </el-popover>
        </a> -->
        <a href="/admin_www" v-if="isWww && $hasPermission('www_manage')">后台编辑</a>
      </div>
      <!-- <router-link to="/">
        <el-button v-if="$route.name.includes('User')" class="go-home-btn" type="primary">返回wiki</el-button>
      </router-link> -->
    </div>
    <div v-if="needLogin || loginUser.is_super" class="r-wrapper">
      <div v-if="!['wikiList', 'wwwSearch'].includes($route.name)" class="search-box">
        <input v-model="kw" placeholder="搜你所想" type="text" @keyup.enter="onSearch" />
        <i class="el-icon el-icon-search" @click="onSearch"></i>
      </div>
      <div class="apply-wiki-btn" @click="onShowDg">
        <i class="wiki-icon wiki-icon-www-index-9 mR3"></i>
        申请wiki
      </div>
      <div v-if="isTeeny" class="quit-teeny-btn" @click="quitTeenyMode">
        退出青少年模式
      </div>
      <template v-if="loginUid > 0">
        <div class="user-box">
          <el-dropdown placement="bottom" @command="onCommand">
            <a class="el-dropdown-link user">
              <img :src="handleImgSize(loginUser.avatar)" alt="" />
              <span>{{ loginUser.username }}</span>
            </a>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="goUserCenter">个人中心</el-dropdown-item>
              <el-dropdown-item command="loginOut"><span style="color: orangered;">退出登录</span></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <a class="msg-btn" href="/msg/1">
          消息<span v-if="redPoint.total > 0">{{ redPoint.total > 99 ? "99+" : redPoint.total }}</span>
        </a>
      </template>
      <template v-else>
        <a @click="onShowLogin">注册/登录</a>
      </template>
    </div>
    <!-- 申请wiki -->
    <apply-wiki-dg ref="applyWikiDg" />
    <!-- 登录 -->
    <login />
    <!-- 实名认证 -->
    <auth-real-name />
    <!-- 青少年检查 -->
    <check-underage />
  </header>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
// components
import Login from "./login";
import AuthRealName from "./authRealName.vue";
import ApplyWikiDg from "@/views/wiki/components/applyWikiDg.vue";
import CheckUnderage from "./checkUnderage.vue";
// plugins
import eventBus from "@/plugins/eventBus";
import { getCookies, setCookies } from "@/plugins/cookies";

export default {
  name: "wikiHeader",
  mixins: [],
  props: {},
  model: {},
  components: {
    ApplyWikiDg,
    Login,
    AuthRealName,
    CheckUnderage
  },
  data() {
    return {
      isShowLogin: false,
      // 是否青少年模式
      isTeeny: false,
      kw: "",
      navGroup: [
        { text: "热门wiki", url: "/wikis" },
        { text: "新游专区", url: "/games" },
        { text: "最新wiki", url: "/wikis" },
        { text: "游戏情报", url: "/news" },
        { text: "游戏视频", url: "/video" }
      ]
    };
  },
  computed: {
    ...mapGetters(["loginUid", "loginUser", "redPoint", "gameInfo"])
  },
  methods: {
    ...mapActions(["loginOut"]),
    onShowDg() {
      this.$refs.applyWikiDg.visible = true;
    },
    onSearch() {
      window.location.href = `//www.${this.wikiDomain}/search?kw=` + (this.kw || "");
      // if (this.isWww) {
      // window.location.href = `//www.${this.wikiDomain}/search?kw=` + (this.kw || '');
      // } else {
      //   window.location.replace(`${this.gameInfo.alias}/list?kw=${this.kw || ''}`)
      // }
    },
    onShowLogin() {
      eventBus.$emit("onShowLogin");
    },
    goUserCenter() {
      let { $route } = this;
      console.log($route);
      if (!$route.name.includes("wikiUser")) {
        window.open("/user/index/" + this.loginUid + ".html");
      } else {
        window.location.replace("/user/index/" + this.loginUid + ".html");
      }
    },
    onCommand(command) {
      if (command == "loginOut") {
        this[command]("goHome");
      } else {
        this[command]();
      }
    },
    quitTeenyMode() {
      setCookies("underageMode", "none", { expires: 365 * 10 });
      window.location.reload();
    }
  },
  watch: {},
  created() {},
  mounted() {
    this.isTeeny = getCookies("underageMode") === "teeny";
  },
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="less">
.app-download-card-popover {
  width: 430px !important;
  height: 140px !important;
  border-radius: 16px !important;
  padding: 0 !important;
  border: none !important;
}
</style>
<style lang="less" scoped>
.app-download-card {
  background: url("../../../assets/images/app-download-card-bg.png") no-repeat;
  background-position: right bottom;
  // background-color: #fff;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 18px;
  border-radius: 16px;
  .download-qrcode {
    width: 104px;
    height: 104px;
    margin-right: 16px;
    padding: 6px;
    border: 1px solid #19b2ff;
    border-radius: 6px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .download-tips {
    .title {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      color: #2e3033;
      margin-bottom: 5px;
    }
    .desc {
      font-size: 14px;
      line-height: 24px;
      color: #2e3033;
    }
  }
  .card-tag {
    position: absolute;
    top: 0;
    right: 0;
    width: 114px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0px 16px 0px 8px;
    background-color: #d4f1ff;
    font-size: 12px;
    line-height: 16px;
    color: #314e5d;
    .wiki-icon {
      display: inline-block;
      width: 14px;
      height: 14px;
      line-height: 16px;
      margin-right: 4px;
      margin-bottom: 2px;
    }
  }
}
.wiki-header {
  width: 100%;
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #404040;
  position: fixed;
  z-index: @middle-z-index;
  top: 0;
  left: 0;

  .l-wrapper {
    display: flex;
    align-items: center;

    .gamekee-logo {
      width: 150px;
      height: 30px;
      background: url("./../../../assets/images/logo3.png") no-repeat center;
      background-size: 100% auto;
      margin-left: 32px;
      margin-right: 12px;
    }

    .hero-logo {
      width: 150px;
      height: 50px;
      background: url("./../../../assets/images/hero_logo.png") no-repeat left center;
      background-size: auto 50px;
      margin-left: 32px;
      margin-right: 12px;
    }

    .go-home-btn {
      margin-left: 20px;
    }
  }

  .r-wrapper {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding-right: 20px;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      transition: 0.3s;
      cursor: pointer;
      margin: 0 15px;

      .iconfont {
        margin-right: 5px;
        color: @basic;
        font-size: 14px;
      }

      &:hover {
        color: @text-primary;
      }
    }

    .user {
      img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        object-fit: cover;
      }

      span {
        margin-left: 5px;
        font-size: 12px;
      }
    }

    .user-box {
      position: relative;
      height: 40px;
      display: flex;
      align-items: center;

      &:hover {
        ul {
          display: block;
        }
      }

      ul {
        display: none;
        position: absolute;
        top: 40px;
        left: 50%;
        transform: translateX(-50%);
        width: 100px;
        background: #fff;
        padding: 5px 10px;
        box-shadow: 0 2px 5px #eee;
        border-radius: 4px;
        text-align: center;
        line-height: 30px;
        font-size: 12px;
        color: #666;

        li {
          a {
            margin: 0;
            color: #666;
          }
        }

        li {
          cursor: pointer;
        }

        li + li {
          border-top: 1px solid @border-basic;
        }
      }
    }
  }
}

.nav {
  height: 54px;
  display: flex;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 20px;
    height: 100%;
    color: #fff;
    font-size: 13px;
  }
}

.search-box {
  width: 160px;
  height: 36px;
  overflow: hidden;
  border-radius: 20px;
  background: #292929;
  position: relative;
  margin-right: 15px;

  input {
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    padding-left: 16px;
    color: #fff;
  }

  .el-icon {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    color: #999999;
  }
}
.apply-wiki-btn {
  width: 106px;
  height: 36px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 18px;
  border: 1px solid #525252;
  font-weight: 500;
  font-size: 13px;
  color: #dbdbdb;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 15px;
}

.quit-teeny-btn {
  width: 95px;
  height: 36px;
  font-weight: 500;
  font-size: 13px;
  color: #dbdbdb;
  line-height: 36px;
  cursor: pointer;
}

.msg-btn {
  position: relative;
  span {
    position: absolute;
    top: -6px;
    left: 20px;
    display: flex;
    align-items: center;
    padding: 0 4px;
    word-break: keep-all;
    background: red;
    border-radius: 10px;
    height: 16px;
    font-size: 12px;
    color: #fff;
  }
}
</style>
