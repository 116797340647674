<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="415px"
    append-to-body
    center
    custom-class="login-dialog"
    @close="onClose"
  >
    <div slot="title" class="login-title">登录/注册</div>
    <el-form ref="loginForm" :model="form" :rules="rules" class="login-form">
      <el-form-item prop="phone">
        <el-input v-model="form.phone" maxlength="11" placeholder="请输入手机号" size="default" />
      </el-form-item>
      <el-form-item prop="code">
        <el-input v-model="form.code" maxlength="6" placeholder="请输入验证码" size="default">
          <el-button slot="append" :disabled="timeCount > 0" class="code-btn" type="default" @click="showImgCode">
            {{ codeText }}
          </el-button>
        </el-input>
      </el-form-item>
      <el-button class="W100" size="default" type="primary" @click="onLogin">
        登 录
      </el-button>
    </el-form>
    <template v-if="!isHeroGame">
      <div class="H10px"></div>
      <el-divider>
        <span class="other-login-title">其他方式登录</span>
      </el-divider>
      <div class="other-login-box">
        <a class="qq-btn" @click="onQQWeChatLogin('qq')"></a>
        <a v-if="device.desktop" class="weixin-btn" @click="onQQWeChatLogin('wechat')"></a>
      </div>
      <label class="xy">
        <el-checkbox v-model="isChecked" />
        勾选即表示同意GameKee
        <a class="" :href="`//www.${wikiDomain}/wiki/91660.html`" target="_blank">
          《隐私政策》
        </a>
        和
        <a :href="`//www.${wikiDomain}/wiki/155685.html`" target="_blank">
          《用户协议》
        </a>
        内容条例
      </label>
    </template>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="imgCodeVisible"
      :width="device.desktop ? '300px' : '75%'"
      append-to-body
      center
      custom-class="login-dialog"
      top="20vh"
    >
      <div slot="title" class="login-title">请输入图片验证码</div>
      <el-form ref="imgForm" :model="form" :rules="rules" class="login-form">
        <el-form-item>
          <el-row justify="space-between" type="flex">
            <el-col :span="14" class="img-box">
              <img :src="imgSrc" alt="" class="captcha-img" />
            </el-col>
            <el-col :span="8">
              <span class="reset-img-btn" @click="getCaptcha">换一张</span>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item prop="img_code">
          <el-input v-model="form.img_code" maxlength="6" placeholder="请输入图形验证码" size="default" />
        </el-form-item>
      </el-form>

      <el-button class="W100" size="default" type="primary" @click="sendSmsCode">
        确定
      </el-button>
    </el-dialog>
  </el-dialog>
</template>

<script>
import { apiPublicGetCaptcha, apiPublicSendSmsCode } from "@/api/publicApi";
import { apiQQAuthUrl, apiUserAuth, apiWechatAuthUrl } from "@/api/userApi";
// plugins
import eventBus from "@/plugins/eventBus";
import { setCookies } from "../../../plugins/cookies";

export default {
  name: "login",
  mixins: [],
  props: {},
  model: {},
  components: {},
  data() {
    return {
      visible: false,
      NODE_ENV: process.env.NODE_ENV,
      imgCodeVisible: false,
      loading: false,
      isChecked: false,
      form: {
        phone: "",
        code: "",
        img_code: "",
        is_hero: this.isHeroGame ? 1 : 0
      },
      imgSrc: "",
      rules: {
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur"
          },
          {
            pattern: /^[1][0-9]{10}$/,
            message: "手机号不正确",
            trigger: "blur"
          }
        ],
        img_code: [
          {
            required: true,
            message: "请输入手机验证码",
            trigger: "blur"
          }
        ],
        code: [
          {
            required: true,
            message: "请输入短信验证码",
            trigger: "blur"
          }
        ]
      },
      codeText: "获取验证码",
      timeCount: 0
    };
  },
  watch: {},
  beforeMount() {
    if (this.isHeroGame) {
      this.isChecked = true;
    }
    eventBus.$on("onShowLogin", this.onOpen);
  },
  methods: {
    // 展示登录弹窗
    onOpen() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    onQQWeChatLogin(type) {
      if (this.loading) return;
      if (!this.isChecked) {
        this.$message("请先同意隐私政策");
        return false;
      }

      let params = {
        state: window.location.href
      };
      this.loading = true;
      let typeApi = {
        qq: apiQQAuthUrl,
        wechat: apiWechatAuthUrl
      };
      typeApi[type](params)
        .then(res => {
          window.location.href = res.data.url;
        })
        .finally(_ => {
          setTimeout(() => {
            this.loading = false;
          }, 2000);
        });
    },

    showImgCode() {
      if (this.timeCount > 0) return;
      this.$refs.loginForm.validateField(["phone"], err => {
        if (!err) {
          this.getCaptcha();
          this.imgCodeVisible = true;
        }
      });
      // this.imgCodeVisible = true;
    },
    // 获取短信验证码
    sendSmsCode() {
      let { form } = this;
      let isErr = false;
      this.$refs.loginForm.validateField(["phone", "img_code"], async err => {
        console.log(err);
        if (err) {
          isErr = true;
        }
      });
      if (isErr) return;
      this.imgCodeVisible = false;
      let params = {
        phone: form.phone,
        img_code: form.img_code,
        is_hero: this.isHeroGame ? 1 : 0
      };
      this.codeText = "60s" + "后重新获取";
      this.timeCount = 60;
      apiPublicSendSmsCode(params).then(res => {
        if (res.code == 0) {
          let timer = setInterval(() => {
            if (this.timeCount == 0) {
              this.codeText = "重新获取";
              clearInterval(timer);
              return;
            }
            this.timeCount--;
            this.codeText = this.timeCount + "后重新获取";
          }, 1000);
        }
      });
    },
    // 获取图形验证码
    getCaptcha() {
      apiPublicGetCaptcha({ is_hero: this.isHeroGame ? 1 : 0 }).then(res => {
        if (res.code == 0) {
          this.imgSrc = res.data.image_url;
        }
      });
    },
    loginTips() {
      return new Promise(resolve => {
        const loginTipsStatus = localStorage.getItem("loginTipsStatus");
        if (!loginTipsStatus) {
          this.$alert("打击整治网络水军，让网络空间正气充盈", "公告", {
            showClose: false,
            confirmButtonText: "确定",
            callback: action => {
              localStorage.setItem("loginTipsStatus", true);
              resolve();
            }
          });
        } else {
          resolve();
        }
      });
    },
    onLogin() {
      this.$refs.loginForm.validate(async valid => {
        console.log(valid);
        if (!valid) return;
        if (!this.isChecked) {
          this.$message("请先同意隐私政策");
          return false;
        }
        const { code, data } = await apiUserAuth(this.form);
        if (code == 0) {
          this.$store.commit("set_userInfo", data.user);
          this.$store.commit("set_userToken", data.token);
          this.$store.commit("set_userUid", data.user.uid);

          setCookies("userToken", data.token);
          setCookies("userUid", data.user.uid);
          this.onClose();
          // 提示网络水军公告
          await this.loginTips();
          window.location.reload();
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.login-title {
  font-size: 18px;
  text-align: center;
}

.img-box {
  display: flex;
  height: auto;
  height: 40px;
}

.reset-img-btn {
  display: block;
  margin: auto;
  width: 50px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
  color: #3fa0f4;
  cursor: pointer;
  user-select: none;
}

.captcha-img {
  background: #f5f5f5;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  line-height: 1;
  overflow-x: hidden;
  object-fit: fill;
}

.code-btn {
  font-weight: 400;
}

.other-login-title {
  color: fade(@text-basic, 50%);
  font-weight: 400;
}

.other-login-box {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 30px;

  a {
    display: flex;
    width: 48px;
    height: 48px;
    background: #f5f5f5;
    border-radius: 50%;
    cursor: pointer;
    border: 1px solid #e8e8e8;

    &.qq-btn {
      background: url("./../../../assets/images/QQ.png") no-repeat center #f7f7f7;
      background-size: 70% 70%;
    }

    &.weixin-btn {
      background: url("./../../../assets/images/weixin.png") no-repeat center #f7f7f7;
      background-size: 70% 70%;
    }
  }
}

.xy {
  font-size: 12px;
  color: @gray;
  cursor: pointer;
  line-height: 32px;
  a {
    color: @text-basic;
  }
}
</style>
