<template>
  <footer class="wiki-footer">
    <div class="footer-content">
      <div class="title">商业合作或问题投诉</div>
      <div class="row1">
        <!-- <span>QQ：2017946590（推荐）</span> -->
        <!-- <el-divider direction="vertical"/> -->
        <span>微信：Heiskee</span>
        <el-divider direction="vertical" />
        <span>邮箱：kf@gamekee.com</span>
        <el-divider direction="vertical" />
        <a :href="`/site-map/3.xml`" target="_blank">网站地图</a>
        <el-divider direction="vertical" />
        <a href="https://www.12377.cn/" target="_blank">互联网违法和不良信息举报中心</a>
        <el-divider direction="vertical" />
        <a href="https://www.12321.cn/" target="_blank">网络不良与垃圾信息举报受理中心</a>
      </div>
      <div class="row2">
        <a :href="`//www.${wikiDomain}/wiki/91660.html`" target="_blank">
          隐私政策
        </a>
        <el-divider direction="vertical" />
        <a :href="`//www.${wikiDomain}/wiki/155685.html`" target="_blank">
          用户协议
        </a>
        <el-divider direction="vertical" />
        <span>天津宇之广科技有限公司</span>
        <el-divider direction="vertical" />
        <span>天津市滨海新区读者新媒体大厦第八层办公室A区875室</span>
        <el-divider direction="vertical" />
        <span>统一社会信用代码：91120221MA06X9QTXB</span>
        <el-divider direction="vertical" />
        <a rel="external nofollow" href="https://beian.miit.gov.cn/" target="_blank">
          津ICP备20005824号-2
        </a>
        <el-divider direction="vertical" />
        <a rel="external nofollow" href="https://www.qinglangtianjin.com/" target="_blank">
          天津市互联网违法和不良信息举报平台
        </a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "wikiFooter"
};
</script>
<style scoped lang="less">
.wiki-footer {
  background: #f7f7f7;
  height: 120px;
  .footer-content {
    width: 1300px;
    height: 100%;
    padding: 10px 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .title {
    font-size: 16px;
    color: #333;
    font-weight: 500;
  }
  .row1,
  .row2 {
    font-size: 12px;
    color: #666;
    a {
      color: inherit;
    }
  }
}
</style>
