<template>
  <div v-if="imgs.length" :class="['img-view', classBase + 'img-view']">
    <button class="close-btn" @click="handleClose">
      <i class="iconfont icon-guanbixiao"></i>
    </button>
    <swiper ref="mySwiper" :auto-destroy="true" :auto-update="true" :options="swiperOptions" class="swiper-box">
      <swiper-slide v-for="(item, index) in imgs" :key="index" class="img-view-item">
        <div class="swiper-zoom-container">
          <img :src="item" alt="" class="swiper-lazy" :class="[!isZoom ? 'zoom-in' : 'zoom-out']" title="双击放大" />
        </div>
      </swiper-slide>
    </swiper>
    <div class="swiper-button-prev img-view-prev"></div>
    <div class="swiper-button-next img-view-next"></div>
    <div class="swiper-pagination img-view-pagination"></div>
  </div>
</template>

<script>
import { directive, Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  name: "imgView",
  mixins: [],
  props: {},
  model: {},
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      imgs: [],
      swiperOptions: {
        initialSlide: 0,
        zoom: {
          maxRatio: 4
        },
        navigation: {
          nextEl: ".img-view-next",
          prevEl: ".img-view-prev"
        },
        pagination: {
          el: ".img-view-pagination",
          type: "fraction"
        },
        on: {
          zoomChange: () => {
            console.log("zoomChange");
            this.isZoom = !this.isZoom;
          }
        }
      },
      isZoom: false
    };
  },
  directives: {
    swiper: directive
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    classBase() {
      return this.device.desktop ? "pc-" : "wap-";
    }
  },
  methods: {
    handleClose() {
      this.$imgViewClose();
    }
  },
  watch: {},
  created() {},
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="less" scoped>
.img-view {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: @max-z-index;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swiper-box {
  width: 100%;
  height: 100%;

  .img-view-item {
    width: 100%;
    height: 100%;
  }
}

.close-btn {
  font-size: 36px;
  color: #fff;
  border: none;
  background: transparent;
  position: absolute;
  top: 50px;
  right: 50px;
  z-index: 100;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
}

.img-view-pagination {
  color: #fff;
  font-size: 16px;
  bottom: 30px;
  text-shadow: 0 0 1px @text-gray;
}

.img-view-next,
.img-view-prev {
  --swiper-theme-color: #fff;
  --swiper-navigation-color: #fff;
  --swiper-navigation-size: 30px;
}

.img-view-next {
  right: 50px;
}

.img-view-prev {
  left: 50px;
}

.wap-img-view {
  .img-view-next,
  .img-view-prev {
    display: none;
  }

  .close-btn {
    top: 10px;
    right: 10px;
    font-size: 24px;
  }
}

.zoom-in {
  cursor: zoom-in;
}
.zoom-out {
  cursor: zoom-out;
}
</style>
