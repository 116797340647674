import wikiMsg from "@/layout/wiki/wikiMsg";

const msgRouter = [
  {
    path: "/msg",
    name: "msg",
    component: wikiMsg,
    meta: {
      title: "消息中心",
      keepAlive: false
    },
    children: [
      {
        path: "/msg",
        name: "msgIndex",
        redirect: "/msg/1",
        meta: {
          title: "消息中心",
          keepAlive: false
        }
      },
      {
        path: "/msg/:type",
        name: "msgType",
        component: () => import("@/views/message/index"),
        meta: {
          title: "消息中心",
          keepAlive: false
        }
      }
    ]
  }
];
export default msgRouter;
