import adminBase from "@/layout/admin/adminBase";
import emptyLayout from "../layout/admin/emptyLayout";

const adminRouter = [
  {
    path: "/:gameAlias/admin",
    name: "admin",
    component: adminBase,
    redirect: "/:gameAlias/admin/wiki_detail",
    meta: {
      title: "后台管理",
      wikiRoute: true
    },
    children: [
      {
        path: "/:gameAlias/admin/wiki_detail",
        name: "admin_wiki_detail",
        component: () => import("@/adminViews/wikiDetail"),
        meta: {
          title: "首页管理",
          wikiRoute: true,
          permission: "wiki_detail_edit"
        }
      },
      {
        path: "/:gameAlias/admin/module",
        name: "admin_module",
        component: emptyLayout,
        redirect: "/:gameAlias/admin/module/home_manage",
        meta: {
          title: "模块管理"
        },
        children: [
          {
            path: "/:gameAlias/admin/module/home_manage",
            name: "admin_home_manage",
            component: () => import("@/adminViews/homeManage"),
            meta: {
              title: "首页模块管理",
              wikiRoute: true,
              permission: "admin_module_manage"
            }
          },
          {
            path: "/:gameAlias/admin/module/wiki_banner",
            name: "admin_wiki_banner",
            component: () => import("@/adminViews/wikiBanner"),
            meta: {
              title: "banner管理",
              wikiRoute: true,
              permission: "wiki_banner_edit"
            }
          },
          {
            path: "/:gameAlias/admin/module/friend_link",
            name: "admin_friend_link",
            component: () => import("@/adminViews/friendLink"),
            meta: {
              title: "友情链接管理",
              wikiRoute: true,
              permission: "game_config"
            }
          },
          {
            path: "/:gameAlias/admin/module/calendar_manage",
            name: "admin_calendar_manage",
            component: () => import("@/adminViews/calendarManage"),
            meta: {
              title: "日历管理",
              wikiRoute: true,
              permission: "calendar_manage"
            }
          }
        ]
      },
      {
        path: "/:gameAlias/admin/menu_manage",
        name: "admin_menu_manage",
        component: () => import("@/adminViews/menuManage"),
        meta: {
          title: "词条管理",
          wikiRoute: true,
          permission: "entry_edit"
        }
      },
      {
        path: "/:gameAlias/admin/cdk_manage",
        name: "admin_cdk_manage",
        component: () => import("./../adminViews/cdkManage"),
        meta: {
          title: "CDK管理",
          wikiRoute: true,
          permission: "www_cdk_manage"
        }
      }

      // {
      //   path: '/:gameAlias/admin/hot_entry',
      //   name: 'admin_hot_entry',
      //   component: () => import('@/adminViews/hotEntry'),
      //   meta: {
      //     title: '热门词条编辑',
      //     wikiRoute: true,
      //     permission: 'wiki_hot_edit'
      //   }
      // },

      // {
      //   path: '/:gameAlias/admin/simulator',
      //   name: 'admin_simulator',
      //   component: () => import('@/adminViews/simulator/simulator'),
      //   meta: {
      //     title: '抽卡模拟器管理',
      //     permission: 'admin_simulator'
      //   }
      // },

      // {
      //   path: '/:gameAlias/admin/strategy_manage',
      //   name: 'admin_strategy_manage',
      //   component: () => import('@/adminViews/strategyManage'),
      //   meta: {
      //     title: '攻略推荐',
      //     wikiRoute: true,
      //     permission: 'admin_strategy_manage'
      //   }
      // },
    ]
  },
  {
    path: "/:gameAlias/admin",
    name: "admin_user",
    component: adminBase,
    redirect: "/:gameAlias/admin/user_manage",
    meta: {
      title: "用户管理"
    },
    children: [
      {
        path: "/:gameAlias/admin/wiki_user",
        name: "admin_wiki_user",
        component: () => import("@/adminViews/wikiUser"),
        meta: {
          title: "用户管理",
          wikiRoute: true,
          permission: "user_manage"
        }
      },
      {
        path: "/:gameAlias/admin/content_review",
        name: "admin_content_review",
        component: () => import("@/adminViews/contentReview"),
        meta: {
          title: "内容审核",
          wikiRoute: true,
          permission: "admin_content_review"
        }
      },
      {
        path: "/:gameAlias/admin/report_manage",
        name: "admin_report_manage",
        component: () => import("@/adminViews/reportManage"),
        meta: {
          title: "举报管理",
          wikiRoute: true,
          permission: "admin_report_manage"
        }
      },
      {
        path: "/:gameAlias/admin/recycle_bin",
        name: "admin_recycle_bin",
        component: () => import("@/adminViews/recycleBin"),
        meta: {
          title: "回收站",
          wikiRoute: true,
          permission: "admin_recycle_bin"
        }
      }
    ]
  },
  {
    path: "/:gameAlias/admin",
    name: "admin_permission",
    component: adminBase,
    redirect: "/:gameAlias/admin/wiki_permission",
    meta: {
      title: "权限管理"
    },
    children: [
      {
        path: "/:gameAlias/admin/admin_apply",
        name: "admin_wiki_apply",
        component: () => import("@/adminViews/adminApply"),
        meta: {
          title: "管理员申请列表",
          wikiRoute: true,
          permission: "role_admin_apply"
        }
      },
      {
        path: "/:gameAlias/admin/wiki_permission",
        name: "admin_wiki_permission",
        component: () => import("@/adminViews/wikiPermission"),
        meta: {
          title: "角色管理",
          wikiRoute: true,
          permission: "role_manage"
        }
      },
      {
        path: "/:gameAlias/admin/wiki_permission_sync",
        name: "admin_wiki_permission_sync",
        component: () => import("@/adminViews/wikiPermissionSync"),
        meta: {
          title: "权限树",
          wikiRoute: true,
          permission: "role_manage"
        }
      }
    ]
  }
];
export default adminRouter;
