import OtherBase from "@/layout/wiki/otherBase";

const otherRouter = [
  {
    path: "/",
    name: "other",
    component: OtherBase,
    meta: {
      title: "其他",
      keepAlive: true
    },
    children: [
      {
        path: "/pal/pz.html",
        name: "breed",
        component: () => import("@/views/other/pal/Breed.vue"),
        meta: {
          title: "幻兽帕鲁-配种",
          keepAlive: true
        }
      }
    ]
  }
];

export default otherRouter;
