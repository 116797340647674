import Vue from 'vue';
import { dateFormat } from './util';
import dayjs from './dayjs';

Vue.filter('dateFormat', function (date, type = 'YYYY-MM-DD HH:mm') {
  if (date > 0) {
    return dayjs.unix(date).format(type);
  }
});
Vue.filter('fromNowTimeFormat', function (date) {
  const diff = dayjs().unix() - date;
  if (diff < 60) {
    return '刚刚';
  } else if (diff < 60 * 60) {
    return `${parseInt(diff / 60)}分钟前`;
  } else if (diff < 60 * 60 * 24) {
    return `${parseInt(diff / 60 / 60)}小时前`;
  } else if (diff < 60 * 60 * 24 * 30) {
    return `${parseInt(diff / 60 / 60 / 24)}天前`;
  } else if (diff < 60 * 60 * 24 * 30 * 12) {
    return `${parseInt(diff / 60 / 60 / 24 / 30)}月前`;
  } else {
    return `${parseInt(diff / 60 / 60 / 24 / 30 / 12)}年前`;
  }
});
Vue.filter('dateFromNow', function (date) {
  let format;
  // 当年不显示年份，非当年显示年份
  if (dayjs().year() === dayjs.unix(date).year()) {
    format = dayjs.unix(date).format('MM月DD日');
  } else {
    format = dayjs.unix(date).format('YYYY年MM月DD日');
  }
  // 三天内格式化日期
  if ((dayjs().unix() - date) <= 60 * 60 * 24 * 3) {
    format = dayjs.unix(date).fromNow();
  }
  if (date == 0) {
    format = '刚刚';
  }
  return format;
});

Vue.filter('sizeK2M', function (size) {
  if (size > 0) {
    return parseInt(size / 1024 / 1024);
  }
});

Vue.filter('videoDuring', function (during) {
  if (during > 0) {
    let minute = parseInt(during / 60);
    let second = parseInt(during % 60);
    second = second < 10 ? ('0' + second) : second;
    minute = minute < 10 ? ('0' + minute) : minute;
    return `${minute}:${second}`;
  } else {
    return '-';
  }
});
