import service from '@/plugins/request';

// 获取图形验证码
export function apiPublicGetCaptcha (params) {
  return service({
    url: '/v1/public/getCaptcha',
    method: 'get',
    params: params
  });
}

// 获取短信验证码
export function apiPublicSendSmsCode (params) {
  return service({
    url: '/v1/public/sendSmsCode',
    method: 'post',
    data: params,
    successToast: true,
    errorToast: true
  });
}

// 获取阿里云视频地址
export function apiPublicGetAliyunVideo (params) {
  return service({
    url: '/aliyunmedia/geturl.html',
    method: 'get',
    params: params,
    errorToast: true
  });
}

// 获取华为云obs token
export function apiGetObsClientSecurityToken (params) {
  return service({
    url: '/v1/public/getObsCredential',
    method: 'get',
    params: params,
    errorToast: true
  });
}

