import Vue from "vue";
import imgView from "./index.vue";

let $imgView = null;
Vue.prototype.$imgViewShow = function(imgs, count) {
  const _imgView = Vue.extend(imgView);

  $imgView = new _imgView({
    el: document.createElement("div")
  });

  $imgView.$store = Vue.__VUEX_STORE__;
  // 插入到 document.body
  document.body.appendChild($imgView.$el);
  $imgView.imgs = imgs;
  $imgView.swiperOptions.initialSlide = count;
};
Vue.prototype.$imgViewClose = function() {
  if ($imgView) {
    document.body.removeChild($imgView.$el);
    $imgView = null;
  }
};
