<template>
  <div class="menu-wrapper">
    <el-menu
      v-if="routers.length"
      ref="elMenu"
      :default-active="defaultActive"
      :default-openeds="defaultOpeneds"
      @select="onSelectMenu"
    >
      <menu-item :items="routers" />
    </el-menu>
  </div>
</template>

<script>
import MenuItem from "./menuItem";
import adminWwwRouter from "@/router/adminWwwRouter";
import { deepClone } from "@/plugins/util";

export default {
  name: "menuContainer",
  mixins: [],
  props: {},
  model: {},
  components: { MenuItem },
  data() {
    return {
      routers: [],
      defaultActive: "",
      // 默认激活的菜单
      defaultOpeneds: []
    };
  },
  computed: {},
  methods: {
    routeChange() {
      if (this.$refs.elMenu) {
        this.$refs.elMenu.activeIndex = this.$route.path;
      }
    },
    onSelectMenu(path) {
      let { $route } = this;
      if ($route.path !== path) {
        this.$router.push(path);
      }
    },
    async loadRouter() {
      this.routers = deepClone(adminWwwRouter);
      // console.log(this.routers);
      this.showDefaultOpeneds();
    },
    showDefaultOpeneds() {
      let { $route } = this;
      this.routers.forEach(item => {
        if (item.children) {
          item.children.forEach(item2 => {
            if (item2.path === $route.path) {
              this.defaultActive = $route.path;
            }
            if (item2.children) {
              item2.children.forEach(item3 => {
                if (item3.path === $route.path) {
                  this.defaultActive = $route.path;
                }
              });
            }
          });
        }
      });
      this.defaultOpeneds = this.routers.map((it, index) => it.path + index);
    }
  },
  watch: {
    $route: "routeChange"
  },
  created() {
    this.loadRouter();
  },
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="less" scoped>
.gameInfo {
  text-align: center;
  padding: 20px;
  border-bottom: 1px solid @border-basic;

  img {
    width: 80px;
    height: 80px;
    border-radius: 10px;
  }
}

.menu-wrapper {
  /deep/ .el-menu {
    border-right: none;

    .el-menu-item {
      border-right: 3px solid transparent;
    }

    .el-menu-item.is-active {
      border-right: 3px solid @basic;
      background: fade(@basic-light, 50%);
    }
  }
}
</style>
