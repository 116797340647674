const roleList = [
  {
    name: "后台管理",
    key: "manage",
    child: [
      {
        name: "wiki资料编辑",
        key: "wiki_detail_edit",
        router: ["/v1/game/edit"]
      },
      {
        name: "wikiBanner编辑",
        key: "wiki_banner_edit",
        router: ["/v1/slider/edit"]
      },
      {
        name: "热门词条编辑",
        key: "wiki_hot_edit",
        router: ["/v1/entry/managerList"]
      },
      {
        name: "编辑游戏扩展信息(友情链接等)",
        key: "game_config",
        router: ["/v1/game/config"]
      },
      {
        name: "首页模块管理",
        key: "admin_module_manage",
        router: ["/v1/module/list", "/v1/module/sort", "/v1/module/hidden"]
      },
      {
        name: "词条编辑",
        key: "entry_edit",
        router: ["/v1/entry/edit"]
      },
      {
        name: "日历管理",
        key: "calendar_manage",
        router: []
      },
      {
        name: "攻略推荐",
        key: "admin_strategy_manage",
        router: []
      },
      {
        name: "举报管理",
        key: "admin_report_manage",
        router: []
      },
      {
        name: "内容审核",
        key: "admin_content_review",
        router: []
      },
      {
        name: "CDK管理",
        key: "www_cdk_manage"
      },
      {
        name: "回收站",
        key: "admin_recycle_bin",
        router: []
      },
      {
        name: "用户管理",
        key: "user_manage",
        router: ["/v1/protected/user/role", "/v1/protected/permission/user/bindRole"]
      },
      {
        name: "申请管理列表",
        key: "role_admin_apply",
        router: []
      },
      {
        name: "角色列表",
        key: "role_manage",
        router: [
          "/v1/protected/permission/role/list",
          "/v1/protected/permission/role/keys",
          "/v1/protected/permission/role/delete"
        ]
      }
    ]
  },
  {
    name: "详情页功能管理",
    key: "detail_manage",
    child: [
      {
        name: "显示签名",
        key: "detail_user_desc",
        router: []
      },
      {
        name: "删除文章",
        key: "detail_manage_del",
        router: ["/v1/content/delete"]
      },
      {
        name: "编辑文章",
        key: "detail_manage_edit",
        router: []
      },
      {
        name: "更换文章绑定",
        key: "detail_bind_content",
        router: []
      },
      {
        name: "文章置顶",
        key: "content_top",
        router: []
      },
      {
        name: "历史记录回滚",
        key: "content_log_rollback",
        router: []
      },
      {
        name: "删除评论",
        key: "detail_comment_del",
        router: []
      },
      {
        name: "评论置顶",
        key: "detail_comment_top",
        router: []
      }
    ]
  },
  {
    name: "编辑器功能管理",
    key: "editor_manage",
    child: [
      {
        name: "文章类型选择",
        key: "editor_manage_type"
      },
      {
        name: "公告选择",
        key: "detail_manage_gonggao",
        router: []
      },
      {
        name: "编辑器选择",
        key: "editor_select_button"
      },
      {
        name: "共享图库删除",
        key: "editor_lib_delete"
      },
      {
        name: "共享图库上传",
        key: "editor_lib_add"
      }
    ]
  },
  {
    name: "wiki首页功能",
    key: "wiki_index",
    child: [
      {
        name: "词条编辑",
        key: "wiki_index_entry_edit"
      }
    ]
  },
  {
    name: "大首页后台管理",
    key: "www_manage",
    child: [
      {
        name: "首页管理",
        key: "www_detail_edit"
      },
      {
        name: "banner管理",
        key: "www_banner_edit"
      },
      {
        name: "模块管理",
        key: "www_module_edit"
      },
      {
        name: "app更新管理",
        key: "admin_www_app_config"
      },
      {
        name: "游戏管理",
        key: "www_game_manage"
      },
      {
        name: "wiki重定向",
        key: "www_game_alias_redirect"
      },
      {
        name: "去云吧游戏管理",
        key: "www_qyun_game_manage"
      },
      {
        name: "其他配置",
        key: "www_qyun_other_config"
      },
      {
        name: "用户管理",
        key: "www_user_manage"
      },
      {
        name: "封禁列表",
        key: "www_blocked_list"
      },
      {
        name: "新游速递列表",
        key: "admin_www_new_game_list"
      },
      {
        name: "系统消息",
        key: "admin_www_sys_msg"
      }
    ]
  }
];

// forEach 遍历数组会自动跳过空元素
const eachFlat = (arr = [], depth = 1) => {
  const result = [];
  // 开始递归
  (function flat(arr, depth) {
    // forEach 会自动去除数组空位
    arr.forEach(item => {
      // 缓存元素
      result.push(item.key);
      // 控制递归深度
      if (Array.isArray(item.child) && depth > 0) {
        // 递归数组
        flat(item.child, depth - 1);
      }
    });
  })(arr, depth);
  // 返回递归结果
  return result;
};
let flatRoleKeys = eachFlat(roleList, Infinity);

export default roleList;
export { flatRoleKeys };
