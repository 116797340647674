<template>
  <div class="banner-container">
    <swiper
      v-if="list.length > 0"
      ref="mySwiper"
      :auto-destroy="true"
      :auto-update="true"
      :options="swiperOptions"
      class="swiper-box"
    >
      <template v-for="item in list">
        <swiper-slide :key="item.id" class="banner-item" v-if="!item.is_app">
          <a class="img-box" :href="item.jump_url || 'javascript:;'" :target="item.jump_url ? '_blank' : '_self'">
            <img :src="item.thumb" alt="" class="swiper-lazy" loading="lazy" />
          </a>
        </swiper-slide>
      </template>
    </swiper>
    <div class="swiper-button-next"></div>
    <div class="swiper-button-prev"></div>
    <div class="swiper-pagination"></div>
  </div>
</template>

<script>
// import ModuleContainer from "./moduleContainer";
import { directive, Swiper, SwiperSlide } from "vue-awesome-swiper";

export default {
  name: "bannerContainer",
  mixins: [],
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  model: {},
  components: {
    Swiper,
    SwiperSlide
    // ModuleContainer
  },
  directives: {
    swiper: directive
  },
  data() {
    return {
      swiperOptions: {
        watchSlidesProgress: true,
        slidesPerView: "auto",
        centeredSlides: true,
        loop: true,
        loopedSlides: 5,
        autoplay: true,
        pagination: {
          el: ".swiper-pagination"
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        on: {
          progress: function(progress) {
            for (let i = 0; i < this.slides.length; i++) {
              const slide = this.slides.eq(i);
              const slideProgress = this.slides[i].progress;
              let modify = 1;
              if (Math.abs(slideProgress) > 1) {
                modify = (Math.abs(slideProgress) - 1) * 0.3 + 1;
              }
              const translate = slideProgress * modify * 759 + "px";
              const scale = 1 - Math.abs(slideProgress) / 5 / 2;
              const zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
              slide.transform("translateX(" + translate + ") scale(" + scale + ")");
              slide.css("zIndex", zIndex);
              slide.css("opacity", 1);
              if (Math.abs(slideProgress) > 3) {
                slide.css("opacity", 0);
              }
            }
          },
          setTransition: function(transition) {
            for (let i = 0; i < this.slides.length; i++) {
              const slide = this.slides.eq(i);
              slide.transition(transition);
            }
          }
        }
      }
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    }
  },
  methods: {
    handleJumpUrl(item) {
      console.log(item);
      if (item.jump_url) {
        window.open(item.jump_url);
      }
    }
  },
  watch: {},
  created() {},
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="less" scoped>
.banner-container {
  width: 100%;
  height: 300px;
  border-radius: 6px;
  margin-bottom: 30px;
  position: relative;

  .swiper-box {
    height: 100%;
    .img-box {
      display: block;
      height: 100%;
      overflow: hidden;
    }
  }
  /deep/ .swiper-slide {
    background: #000;
    img {
      opacity: 0.5;
    }
  }
  /deep/ .swiper-slide-active {
    background: transparent;
    img {
      width: 800px;
      height: 100%;
      object-fit: cover;
      border-radius: 6px;
      background: #fff;
      display: block;
      margin: auto;
      opacity: 1;
    }
  }
}

.swiper-pagination {
  width: 100%;
  text-align: center;
  display: none;

  /deep/ .swiper-pagination-bullet {
    width: 12px;
    height: 4px;
    background: #3c3c3c;
    border-radius: 3px;
    opacity: 1;
    margin: 0 2px;
  }

  /deep/ .swiper-pagination-bullet-active {
    width: 17px;
    background: #ffd47d;
  }
}
.swiper-button-prev {
  width: 32px;
  height: 80px;
  color: #fff;
  margin-top: -35px;
  left: 180px;
  background: rgba(0, 0, 0, 0.35);
  &::after {
    font-size: 20px;
  }
}
.swiper-button-next {
  width: 32px;
  height: 80px;
  color: #fff;
  margin-top: -35px;
  background: rgba(0, 0, 0, 0.35);
  right: 180px;
  &::after {
    font-size: 20px;
  }
}
</style>
