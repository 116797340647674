import Vue from 'vue';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import relativeTime from 'dayjs/plugin/relativeTime';
import toObject from 'dayjs/plugin/toObject';
import isBetween from 'dayjs/plugin/isBetween';
import duration from 'dayjs/plugin/duration';
dayjs.locale('zh-cn');
dayjs.extend(relativeTime);
dayjs.extend(toObject);
dayjs.extend(isBetween);
dayjs.extend(duration);
Vue.prototype.$dayjs = dayjs;

export default dayjs;
