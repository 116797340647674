<template>
  <!-- 未成年提示 -->
  <el-dialog
    custom-class="underage-dialog"
    :visible.sync="visible"
    width="464px"
    append-to-body
    :show-close="false"
    :close-on-click-modal="false"
  >
    <div class="dialog-container">
      <div class="logo">
        <img src="@/assets/images/logo3.png" alt="" />
      </div>
      <div class="desc">
        <p>为呵护未成年人健康成长，GameKee特别推出青少年</p>
        <p>模式，该模式下部分WIKI无法正常显示</p>
      </div>
      <div class="btns">
        <button class="text-btn" @click="handleCheckUnderage(0)">
          进入青少年模式 <i class="el-icon-arrow-right"></i>
        </button>
        <button class="primary-btn" @click="handleCheckUnderage(1)">我知道了</button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
// plugins
import eventBus from "@/plugins/eventBus";
import { getCookies, setCookies } from "@/plugins/cookies";

export default {
  name: "CheckUnderage",
  data() {
    return {
      visible: false
    };
  },
  computed: {
    ...mapGetters(["loginUid"])
  },

  watch: {
    loginUid(val) {
      console.log(val);
      if (val) {
        console.log("./assets/images/logo3.png");
      }
    }
  },
  mounted() {
    eventBus.$on("checkUnderage", () => {
      this.underageCheck();
    });
  },
  methods: {
    // 未成年弹框检查
    underageCheck() {
      const gameInfo = this.$store.getters.gameInfo;
      const currentRouteMeta = this.$route.meta || {};
      const loginUser = this.$store.getters.loginUser;
      // 不检查的路由
      if (currentRouteMeta.noUnderageCheck) return;
      // 已登录
      if (loginUser.uid) return;
      // 判断百度来源，限制is_limit_age游戏跳转首页
      // if (gameInfo.is_limit_age && document.referrer.includes("baidu.com")) {
      //   location.href = "/";
      //   return;
      // }
      const underageMode = getCookies("underageMode");
      // console.log(underageMode);
      if (!underageMode) {
        this.visible = true;
      } else if (currentRouteMeta.wikiRoute && gameInfo.is_limit_age && underageMode === "teeny") {
        location.href = "/";
      }
    },
    // 未成年弹框判断
    handleCheckUnderage(type) {
      this.visible = false;
      if (type === 0) {
        // 青少年模式
        setCookies("underageMode", "teeny", { expires: 365 * 10 });
        location.href = "/";
      } else {
        setCookies("underageMode", "none", { expires: 365 * 10 });
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__wrapper {
  backdrop-filter: blur(10px);
}
/deep/ .underage-dialog .el-dialog__header {
  display: none;
}
.dialog-container {
  .logo {
    width: 100%;
    text-align: center;
    margin-bottom: 41px;
    margin-top: 20px;
    img {
      width: 227px;
      height: 34px;
    }
  }
  .desc {
    font-weight: 400;
    font-size: 14px;
    color: #171717;
    line-height: 20px;
    margin-bottom: 32px;
    p {
      text-align: center;
      margin: 0;
    }
  }
  .btns {
    text-align: center;
    margin-bottom: 20px;
    button {
      width: 400px;
      height: 37px;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
    .text-btn {
      height: 20px;
      background-color: #fff;
      border: none;
      color: #bdbec1;
      margin-bottom: 20px;
    }
    .primary-btn {
      background: #00afe8;
      border: 1px solid #00afe8;
      color: #fff;
    }
  }
}
</style>
