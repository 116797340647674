<template>
  <div class="www-secondary-base" :style="setBg">
    <wiki-header />
    <div class="www-secondary-body">
      <!--      <banner-expand :list="bannerList"/>-->
      <banner-container :list="bannerList" />
      <router-view />
    </div>
    <wiki-footer />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// components
import WikiHeader from "@/layout/wiki/components/wikiHeader.vue";
import WikiFooter from "./components/wikiFooter.vue";
import BannerContainer from "@/views/www/components/bannerContainer.vue";
// apis
import { apiWwwIndexSlider } from "@/api/wwwApi";
// plugins
// import eventBus from "@/plugins/eventBus";
// mixins
import mergeAsyncDataMixin from "@/mixins/mergeAsyncDataMixin";

export default {
  name: "wwwSecondaryBase",
  mixins: [mergeAsyncDataMixin],
  components: {
    WikiFooter,
    BannerContainer,
    WikiHeader
  },
  data() {
    return {
      bannerList: []
    };
  },
  async asyncData({ $apis }) {
    try {
      console.log("asyncData start");
      // 数据预取
      const { code, data } = await $apis.apiWwwIndexSlider();
      if (code != 0) return;
      const bannerList = data;
      return {
        bannerList
      };
    } catch (err) {
      console.log(err);
    }
  },
  computed: {
    ...mapGetters(["gameInfo", "isHeroGame"]),
    setBg() {
      let { Config } = this.gameInfo;
      // 个人中心不展示背景
      let style = {};
      if (Config && Config.backend_img && Config.backend_img.length) {
        style.background = `url(${Config.backend_img}) no-repeat fixed 50% 0`;
        style.backgroundSize = "cover";
      }
      return style;
    }
  },
  created() {},
  beforeMount() {
    this.getBannerList();
  },
  methods: {
    async getBannerList() {
      let res = await apiWwwIndexSlider();
      if (res.code !== 0) return;
      this.bannerList = res.data;
    }
  }
};
</script>
<style scoped lang="less">
.www-secondary-base {
  padding-top: 70px;
  min-height: 100vh;
  .www-secondary-body {
    background: #fff;
    margin: auto;
    width: 1330px;
    min-height: 900px;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;
  }
}
</style>
