<template>
  <div>
    <template v-for="(item, index) in items">
      <!-- level 1 -->
      <el-submenu :index="item.path + index" :key="index" v-if="getPermission(item)">
        <span slot="title">{{ item.meta ? item.meta.title : "" }}</span>
        <el-menu-item-group>
          <!-- level 2 -->
          <template v-for="(item2, index2) in item.children">
            <el-menu-item v-if="hasRouter(item2) && !item2.children" :route="item2" :key="index2" :index="item2.path">
              <div :data-role="item2.meta.permission"></div>
              {{ item2.meta ? item2.meta.title : "" }}
            </el-menu-item>
            <!-- level 3 -->
            <el-submenu v-else :index="item2.path" :key="'submenu-' + index2">
              <template slot="title">{{ item2.meta ? item2.meta.title : "" }}</template>
              <el-menu-item-group>
                <template v-for="(item3, index3) in item2.children">
                  <el-menu-item v-if="hasRouter(item3)" :route="item3" :key="index3" :index="item3.path">
                    <div :data-role="item3.meta.permission"></div>
                    {{ item3.meta ? item3.meta.title : "" }}
                  </el-menu-item>
                </template>
              </el-menu-item-group>
            </el-submenu>
          </template>
        </el-menu-item-group>
      </el-submenu>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import eventBus from "@/plugins/eventBus";

export default {
  name: "menuItem",
  mixins: [],
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  model: {},
  components: {},
  data() {
    return {
      heroGameWhite: ["/wiki_manage/game_detail", "/wiki_manage/version_manage", "/wiki_manage/friend_link"]
    };
  },
  computed: {
    ...mapGetters(["roleInfo", "isHeroGame"])
  },
  methods: {
    getPermission(item) {
      const res = item.children.map(item => item.meta.permission || "").filter(item => item);
      return res ? this.$hasPermission(res) : false;
    },
    hasRouter(item, item2) {
      let bool = true;
      let permission = item.meta ? item.meta.permission : "";
      if (!this.roleInfo.includes(permission)) {
        bool = false;
      }
      if (this.isHeroGame && this.heroGameWhite.includes(item.path)) {
        bool = false;
      }
      return bool;
    }
  },
  watch: {},
  created() {},
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style scoped lang="less"></style>
