const userRouter = [
  // 图鉴详情内容，外部嵌入页
  {
    path: "/external/book-content/:id",
    name: "book-content",
    component: () => import("@/views/external/BookContentView.vue"),
    meta: {
      title: "详情",
      noUnderageCheck: true
    }
  }
];
export default userRouter;
