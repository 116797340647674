<template>
  <header class="main-header">
    <div class="info-container">
      <div class="info-bar">
        <div class="left">
          <a :href="toPath" class="links">
            主页
          </a>
        </div>
        <div v-if="false" class="right">
          <div class="search">
            <input placeholder="搜索" type="text" />
            <div class="btn wiki-icon-www-head-search"></div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Header",
  props: {
    isInfoBar: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {};
  },
  computed: {
    ...mapGetters(["gameInfo"]),
    toPath() {
      const alias = this.gameInfo.alias;
      const isHasWiki = this.gameInfo.wiki_created_at != 0;
      if (alias !== "www") {
        if (!isHasWiki) {
          return "/" + alias + "/game";
        }
        return "/" + alias + "/";
      }
      return "/";
    }
  },
  created() {},
  methods: {}
};
</script>

<style lang="less" scoped>
.main-header {
  width: 100%;
  height: 60px;

  .info-container {
    width: 100%;
    height: 60px;
    background-color: #fff;
    border-bottom: 1px solid @border-basic;
    z-index: 10;
    top: 0;
    left: 0;
    position: fixed;

    .info-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 1140px;
      height: 100%;
      margin: 0 auto;
      padding: 0 20px;
      box-sizing: border-box;

      .left {
        display: flex;
        align-items: center;

        .game {
          width: auto;
          height: 46px;
        }

        .links {
          position: relative;
          width: 60px;
          height: 100%;
          text-align: center;
          line-height: 60px;
          color: #4b525b;
          font-size: 16px;
          text-decoration: none;
          transition: all ease 0.3s;

          &:after {
            content: "";
            position: absolute;
            bottom: -1px;
            left: 50%;
            opacity: 0;
            width: 20px;
            height: 2px;
            background-color: #00b64f;
            transition: all ease 0.3s;
            transform: translateX(-50%);
          }

          &:hover {
            color: #00b64f;
            // font-weight: bold;
            &:after {
              opacity: 1;
            }
          }
        }
      }

      .right {
        display: flex;
        align-items: center;

        .search {
          position: relative;
          width: 240px;
          height: 34px;

          input {
            width: 100%;
            height: 100%;
            padding: 0 40px 0 20px;
            border: none;
            box-sizing: border-box;
            border-radius: 30px;
            outline: none;
            background-color: #eeeeee;
          }

          .btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 40px;
            height: 34px;
            background-size: 24px 24px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
