
export default {
  data() {
    return {
      fullHeight: 600,
      scrollY: 0,
      isMainBoxScroll: false
    };
  },
  methods: {
    setScreenInfo() {
      const windowHeight = window.screen && window.screen.height;
      const mainBox = document.getElementById("wikiMainBox");
      let res = 0;
      if (windowHeight) {
        res += windowHeight;
      }
      if (mainBox) {
        res -= mainBox.offsetTop;
      }
      this.fullHeight = res;
      // if (res) {
      //   const routeName = this.$route.name;
      //   let height = routeName === 'wikiHome' ? 150 : 242;
      //   this.fullHeight = res - height;
      // }
    },
    // 左侧box滚动
    leftBoxScroll(event) {
      if (!event.deltaY || event.deltaY === 0) return;
      this.mainBoxScroll(event);
    },
    // 右侧box滚动
    rightBoxScroll(event) {
      if (!event.deltaY || event.deltaY === 0) return;
      this.mainBoxScroll(event);
    },
    // 主滚动
    mainBoxScroll(event) {
      const scrollHeight = window.document.documentElement.scrollHeight || window.document.body.scrollHeight;
      const clientHeight = window.document.documentElement.clientHeight || window.document.body.clientHeight;
      const scrollTop = scrollHeight - clientHeight;

      if (this.isMainBoxScroll) return;
      this.isMainBoxScroll = true;
      if (event.deltaY > 0) {
        this.customScrollTo(scrollTop, () => {
          this.isMainBoxScroll = false;
        });
      } else {
        this.customScrollTo(0, () => {
          this.isMainBoxScroll = false;
        });
      }
    },
    // 左侧文章回到顶部
    leftScrollTop() {
      const leftScroll = document.getElementById("leftScroll");
      if (leftScroll && leftScroll.scrollTop > 0) {
        leftScroll.scrollTo({
          top: 0
        });
      }
    },
    // 右侧文章回到顶部
    rightScrollTop() {
      const rightScroll = document.getElementById("rightScroll");
      if (rightScroll && rightScroll.scrollTop > 0) {
        rightScroll.scrollTo({
          top: 0
        });
      }
    },
    handleBaseWheel(event) {
      let scrollDelta = event.deltaY;
      if (!scrollDelta || scrollDelta === 0) return;
      const curRoute = this.$route.name;
      if (curRoute === "wikiNew" || curRoute === "wikiGame") {
        const leftScroll = document.getElementById("leftScroll");
        const rightScroll = document.getElementById("rightScroll");
        // const mainBox = document.getElementById('wikiMainBox');
        const scrollTop = window.document.documentElement.scrollTop || window.document.body.scrollTop;
        const scrollHeight = window.document.documentElement.scrollHeight || window.document.body.scrollHeight;
        const clientHeight = window.document.documentElement.clientHeight || window.document.body.clientHeight;
        scrollDelta = scrollDelta > 60 ? 60 : scrollDelta;
        scrollDelta = scrollDelta < -60 ? -60 : scrollDelta;
        if (scrollDelta > 0 && Math.ceil(scrollHeight) !== Math.ceil(scrollTop + clientHeight)) {
          // 必须滚到底才让内部滚动
          return;
        }
        if (scrollDelta < 0 && scrollTop > 0) {
          // 必须滚到顶才让内部滚动
          return;
        }
        if (leftScroll) {
          const leftScrollTop = leftScroll.scrollTop || 0;
          leftScroll.scrollTo({
            top: leftScrollTop + scrollDelta
            // behavior: 'smooth'
          });
        }
        if (rightScroll) {
          const rightScrollTop = rightScroll.scrollTop || 0;
          rightScroll.scrollTo({
            top: rightScrollTop + scrollDelta
            // behavior: 'smooth'
          });
        }
      }
    },
    customScrollTo(offset, callback) {
      const fixedOffset = offset.toFixed();
      const onScroll = function() {
        const scrollY = window.document.documentElement.scrollTop || window.document.body.scrollTop;
        if (scrollY.toFixed() === fixedOffset) {
          window.removeEventListener("scroll", onScroll);
          if (callback) {
            callback();
          }
        }
      };
      window.addEventListener("scroll", onScroll);
      onScroll();
      window.scrollTo({
        top: offset,
        behavior: "smooth"
      });
    },
    winScroll() {
      this.scrollY = window.document.documentElement.scrollTop || window.document.body.scrollTop;
    }
  },
  mounted() {
    this.setScreenInfo();
    window.addEventListener("resize", this.setScreenInfo);
    // window.addEventListener('wheel', event => {
    //   console.log(1234, event)
    // });
    // window.addEventListener('scroll', this.winScroll);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setScreenInfo);
    // window.removeEventListener('scroll', this.winScroll);
  }
};
