<template>
  <div>
    <div class="top-user-box">
      <div class="l-user-wrapper">
        <div class="user-avatar">
          <img class="avatar-img" :src="user.avatar" alt="" />
          <img v-if="user.status < 1" class="avatar-ban" src="@/assets/images/avatar-ban-2.png" alt="" />
        </div>

        <div class="user-infos">
          <div class="username">
            {{ user.username }}
            <span>uid：{{ user.uid }}</span>
            <span>IP属地：{{ user.last_ip_area }}</span>
          </div>
          <!-- <user-tag-group :user="user"/> -->
          <div class="sign" v-if="user.status >= 1">{{ user.description || "这个人很神秘，什么也没有留下~" }}</div>
          <div v-if="user.status < 1" style="color: #FF0000;">该玩家因违反社区公约，已被封禁处理</div>

          <div v-if="user.status >= 1">
            <template v-if="user.uid == loginUid">
              <el-button type="primary" plain @click="$router.push('/user/edit')">编辑资料</el-button>

              <el-button type="danger" plain @click="loginOut('goHome')">退出登录</el-button>
            </template>
            <template v-else>
              <el-button @click="onFollow" type="primary" plain>
                {{ is_followed ? "已关注" : "关注Ta" }}
              </el-button>
              <el-button v-if="loginUser.is_super" type="primary" plain @click="openPersonalLetter">私信</el-button>
              <el-dialog
                title="私信"
                :visible.sync="personalLetterVisible"
                width="460px"
                :before-close="closePersonalLetter"
              >
                <div class="letter-container">
                  <div class="user">
                    <img class="avatar" :src="user.avatar" alt="" />
                    <span class="name"> {{ user.username }}</span>
                  </div>
                  <div class="letter">
                    <el-input type="textarea" :rows="8" placeholder="请输入内容" v-model="letter"> </el-input>
                  </div>

                  <div class="btns">
                    <el-button @click="closePersonalLetter" style="width: 166px; height: 48px">取消</el-button>
                    <el-button type="primary" @click="sendLetter" style="width: 166px; height: 48px">发送</el-button>
                  </div>
                </div>
              </el-dialog>
            </template>
          </div>
        </div>
      </div>
      <div class="r-data-wrapper" v-if="user.status >= 1">
        <div>
          <span>{{ user.subscribe_num || 0 }}</span>
          <p>关注</p>
        </div>
        <div>
          <span>{{ user.follow_num || 0 }}</span>
          <p>粉丝</p>
        </div>
        <div v-if="false">
          <span>255</span>
          <p>声望</p>
        </div>
        <div v-if="false">
          <span>128</span>
          <p>贡献</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { apiUserAddFollow } from "@/api/userApi";
import { apiAdminPersonalLetter } from "@/api/wwwApi";
// import UserTagGroup from "../../../components/UserTag/UserTagGroup";

export default {
  name: "wikiUserPcHead",
  mixins: [],
  props: {
    user: {
      type: Object,
      default: () => ({})
    },
    is_followed: {
      type: Number,
      default: 0
    }
  },
  model: {},
  components: {},
  data() {
    return {
      personalLetterVisible: false,
      letter: ""
    };
  },
  computed: {
    ...mapGetters(["loginUser"])
  },
  methods: {
    ...mapActions(["loginOut"]),

    onFollow() {
      let uid = this.user.uid;
      apiUserAddFollow({
        follow_uid: uid
      }).then(res => {
        this.$emit("update:is_followed", this.is_followed ? 0 : 1);
      });
    },
    openPersonalLetter() {
      this.personalLetterVisible = true;
    },
    closePersonalLetter() {
      this.personalLetterVisible = false;
      this.letter = "";
    },
    sendLetter() {
      if (!this.letter) {
        this.$message.warning("请输入私信内容");
        return;
      }
      apiAdminPersonalLetter({
        uid: this.user.uid + "",
        msg: this.letter
      }).then(res => {
        if (res && res.code == 0) {
          this.closePersonalLetter();
        }
      });
    }
  },
  watch: {},
  created() {},
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style scoped lang="less">
.letter-container {
  .user {
    display: flex;
    gap: 11px;
    margin-bottom: 20px;
    align-items: center;
    .avatar {
      width: 66px;
      height: 66px;
      border-radius: 50%;
      object-fit: cover;
    }
    .name {
      font-weight: bold;
      font-size: 18px;
      color: #333333;
    }
  }

  .btns {
    margin-top: 26px;
    margin-bottom: 12px;
    display: flex;
    justify-content: space-between;
  }
}
.top-user-box {
  background: url("../../../assets/images/user-info-bg.png") no-repeat;
  background-size: 100% 100%;
  padding: 24px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  border-radius: 6px;

  .l-user-wrapper {
    display: flex;
    align-items: stretch;

    .user-avatar {
      position: relative;
      width: 120px;
      height: 120px;
      .avatar-img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
      .avatar-ban {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    > .user-infos {
      min-height: 120px;
      padding-left: 28px;

      .username {
        font-size: 18px;
        font-weight: 600;
        color: #151515;
        margin-top: 10px;
        margin-bottom: 20px;

        span {
          font-size: 14px;
          color: #8b8b8b;
          font-weight: initial;
          margin-left: 10px;
        }
      }

      .sign {
        font-size: 16px;
        color: #404040;
        max-width: 500px;
        margin-bottom: 20px;
      }

      .edit-btn,
      .follow-btn {
        display: flex;
        align-items: center;
        border: 1px solid @basic;
        color: @basic;
        background: #fff;
        border-radius: 4px;
        height: 36px;
        padding: 0 14px;
        transition: 0.3s;

        &:hover {
          background: @basic-light;
        }

        &.active {
          background: @basic;
          color: #fff;
        }
      }
    }
  }

  .r-data-wrapper {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #8b8b8b;

    > div {
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #666666;
    }

    span {
      font-size: 28px;
      font-weight: bold;
    }
    p {
      font-size: 14px;
      margin-top: 8px;
    }
  }
}
</style>
