import wwwBase from "../layout/wiki/wwwBase";
import wwwSecondaryBase from "@/layout/wiki/wwwSecondaryBase.vue";

const wwwRouter = [
  {
    path: "/",
    component: wwwBase,
    meta: {
      title: "gamekee",
      keepAlive: true
    },
    children: [
      // 主页
      {
        path: "/",
        name: "wwwHome",
        component: () => import("@/views/www/index"),
        meta: {
          title: "GameKee | 游戏百科攻略",
          keywords: "gamekee,游戏百科,游戏攻略",
          description:
            "GameKee（简称K站）致力于为好游戏提供wiki百科和情报攻略，在这里每个玩家都可以为自己喜欢的游戏搭建wiki专区，希望通过我们的努力，让更多的好游戏能被发现，也欢迎各位游戏热爱者加入我们，一起为自己喜欢的游戏做点有意义的事情。",
          keepAlive: true
          // grayBody: true
        }
      },
      // 主页 /www
      {
        path: "/www",
        redirect: "/"
      },
      // 主页 /home.html
      {
        path: "/home.html",
        redirect: "/"
      },
      // 搜索页
      {
        path: "/search",
        name: "wwwSearch",
        component: () => import("@/views/www/search"),
        meta: {
          title: "gamekee",
          keepAlive: true
        }
      },
      {
        path: "/www/search",
        redirect: "/search"
      },
      {
        path: "/contributor",
        name: "wwwContributor",
        component: () => import("@/views/wiki/detail/ContributorList"),
        meta: {
          title: "wiki"
        }
      },
      {
        path: "/:id.html",
        name: "wwwDetail",
        component: () => import("@/views/wiki/detail"),
        meta: {
          title: "wiki"
        }
      },
      {
        path: "/m/:id.html",
        redirect: "/:id.html"
      }
    ]
  },
  {
    path: "/",
    component: wwwSecondaryBase,
    children: [
      // 热门游戏
      {
        path: "/wikis",
        name: "wikis",
        component: () => import("@/views/www/wikiLibList.vue"),
        meta: {
          title: `好玩不花钱的安卓手游_手游排行榜${new Date().getFullYear()}前十名_策略手游游戏|Gamekee`,
          keywords: `手游排行榜,策略手游游戏,好玩不花钱的安卓手游,好玩的仙侠手游排行榜${new Date().getFullYear()},手游养成类游戏排行榜`,
          description: `GameKee的最新wiki专栏为您带来好玩的手游排行榜${new Date().getFullYear()}前十名；这里有好玩的仙侠手游排行榜，也有手游养成类游戏排行榜，更有策略手游游戏推荐给大家，方便大家找到好玩不花钱的安卓手游和IOS手游。`
        }
      },
      {
        path: "/www/wiki/lib",
        redirect: "/wikis"
      },
      // 新游专区
      {
        path: "/games",
        name: "games",
        component: () => import("@/views/www/newGameList.vue"),
        meta: {
          title: "新游专区|Gamekee"
        }
      },
      {
        path: "/www/game/list",
        redirect: "/games"
      },
      // 游戏视频
      {
        path: "/video",
        name: "video",
        component: () => import("@/views/www/videoList.vue"),
        meta: {
          title: "游戏视频|Gamekee"
        }
      },
      {
        path: "/www/video/list",
        redirect: "/video"
      },
      // 游戏情报
      {
        path: "/news",
        name: "news",
        component: () => import("@/views/www/newsList.vue"),
        meta: {
          title: "游戏情报|Gamekee"
        }
      },
      {
        path: "/www/news/list",
        redirect: "/news"
      },

      // k站推荐
      // {
      //   path: "/www/recommend/list",
      //   name: "wwwRecommendList",
      //   component: () => import("@/views/www/recommendList.vue"),
      //   meta: {
      //     title: "k站推荐|Gamekee"
      //   }
      // },

      // 兼容noWikiRoute
      {
        path: "/game/:gameId",
        name: "noWikiGame",
        component: () => import("@/views/wiki/game/index"),
        meta: {
          noWikiRoute: true
        }
      },
      {
        path: "/game/:gameId/list",
        name: "noWikiList",
        component: () => import("@/views/wiki/list/index"),
        meta: {
          noWikiRoute: true
        }
      },
      {
        path: "/game/:gameId/:id.html",
        name: "noWikiDetail",
        component: () => import("@/views/wiki/detail"),
        meta: {
          noWikiRoute: true
        }
      }
    ]
  },
  // 编辑器
  {
    path: "/editor",
    name: "wwwEditor",
    component: () => import("@/views/editor/index"),
    meta: {
      title: "Gamekee"
    }
  },
  // 图鉴
  {
    path: "/illustrated-book",
    name: "www-illustrated-book",
    component: () => import("@/views/editor/wiki-editor/illustrated-book"),
    meta: {
      title: "图鉴",
      keepAlive: true
    }
  },
  // 登录回调
  {
    path: "/callback",
    name: "callback",
    component: () => import("@/views/www/loginCallback"),
    meta: {
      title: "wiki",
      keepAlive: true
    }
  }
];

export default wwwRouter;
