// layouts
import wikiBase from "@/layout/wiki/wikiBase";
// import SimulatorBase from '../layout/wiki/simulatorBase';

// routes
// import { pcDynamicRoute, wapDynamicRoute } from '@/router/dynamicRoute';

const wikiRouter = [
  {
    path: "/:gameAlias/",
    component: wikiBase,
    meta: {
      title: "wiki",
      keepAlive: true
    },
    children: [
      // wiki主页
      {
        path: "/:gameAlias/",
        name: "wikiHome",
        component: () => import("@/views/wiki/home/index"),
        meta: {
          wikiRoute: true,
          keepAlive: true
          // grayBody: true
        }
      },
      // 其他路由主页重定向
      {
        path: "/:gameAlias/home",
        redirect: "/:gameAlias"
      },
      {
        path: "/:gameAlias/m",
        redirect: "/:gameAlias"
      },
      {
        // 此路由仅wap端存在，PC需要重定向到首页
        path: "/:gameAlias/entry",
        redirect: "/:gameAlias"
      },
      // ...Device.desktop ? pcDynamicRoute : wapDynamicRoute,
      // 新wiki主页
      {
        path: "/:gameAlias/new",
        name: "wikiNew",
        component: () => import("@/views/wiki/new/index"),
        meta: {
          title: "wiki",
          wikiRoute: true,
          keepAlive: true
        }
      },
      // wiki游戏
      {
        path: "/:gameAlias/game",
        name: "wikiGame",
        component: () => import("@/views/wiki/game/index"),
        meta: {
          wikiRoute: true,
          keepAlive: true
        }
      },
      // wiki社区
      {
        path: "/:gameAlias/list",
        name: "wikiList",
        component: () => import("@/views/wiki/list/index"),
        meta: {
          title: "社区列表",
          wikiRoute: true,
          keepAlive: true
        }
      },
      {
        path: "/:gameAlias/contributor",
        name: "wikiDetailContributor",
        component: () => import("@/views/wiki/detail/ContributorList"),
        meta: {
          title: "wiki",
          wikiRoute: true
        }
      },
      {
        path: "/:gameAlias/:id.html",
        name: "wikiDetail",
        component: () => import("@/views/wiki/detail"),
        meta: {
          title: "wiki",
          wikiRoute: true
        }
      },
      {
        path: "/:gameAlias/m/:id.html",
        redirect: "/:id.html"
      }
    ]
  },
  // wap编辑器
  {
    path: "/:gameAlias/wap-editor",
    name: "wikiWapEditor",
    component: () => import("@/views/wapEditor/Index"),
    meta: {
      title: "编辑器",
      wikiRoute: true,
      keepAlive: false
    }
  },
  // 编辑器
  {
    path: "/:gameAlias/editor",
    name: "editor",
    component: () => import("@/views/editor/index"),
    meta: {
      title: "Gamekee",
      wikiRoute: true
    }
  },
  // 图鉴
  {
    path: "/:gameAlias/illustrated-book",
    name: "/illustrated-book",
    component: () => import("@/views/editor/wiki-editor/illustrated-book"),
    meta: {
      title: "图鉴",
      wikiRoute: true,
      keepAlive: true
    }
  }
];

export default wikiRouter;
