<template>
  <i :style="{ fontSize: size + 'px' }" :class="['svgicon', name]"> </i>
</template>

<script>
export default {
  name: "SvgIcon",
  props: {
    name: {
      type: String,
      required: true
    },
    size: {
      type: [Number, String],
      default: 14
    }
  },
  model: {},
  components: {},
  data() {
    return {};
  },

  computed: {},
  methods: {},
  watch: {},
  created() {},
  mounted() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {}
};
</script>

<style lang="less" scoped>
.svg-icon {
  display: inline-block;
  vertical-align: middle;

  .icon {
    width: 1em;
    height: 1em;
    line-height: 1em;
    fill: currentColor;
    overflow: hidden;
    vertical-align: middle;
    margin-top: -0.2em;
  }
}
</style>
