import Vue from "vue";
import VueLazyload from "vue-lazyload";
// Vue.use(VueLazyload);
// 配置项
Vue.use(VueLazyload, {
  preLoad: 2,
  error: require("../assets/images/local.png"),
  // loading: require("../assets/images/local.png"),
  attempt: 1
});
