import Cookies from "js-cookie";

/**
 * 获取cookies
 * @param {string} name 名称
 * @returns value
 */
export function getCookies(name) {
  return Cookies.get(name);
}

/**
 * 设置cookies
 * @param {*} name 名称
 * @param {*} value 值
 * @param {*} option 选项
 */
export function setCookies(name, value, option) {
  return Cookies.set(name, value, {
    path: "/",
    domain: ".gamekee.com",
    expires: 15,
    ...option
  });
}

/**
 * 删除cookies
 * @param {*} name 名称
 * @param {*} option 选项
 */
export function removeCookies(name, option) {
  return Cookies.remove(name, {
    path: "/",
    domain: ".gamekee.com",
    ...option
  });
}
